import { ApplicationRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { Modal, ModalActionType } from 'components/modal/modal.model';
import { Subject, concat, interval } from 'rxjs';
import { filter, first, switchMap, takeUntil } from 'rxjs/operators';

import { LocalDeviceUtility } from '@weavix/domain/src/utils/local-device-utility';
import { SnackBarComponent } from 'components/snack-bar/snack-bar.component';
import { environment } from 'environments/environment';
import { AlertService, SnackBar } from 'weavix-shared/services/alert.service';
import { PttService } from 'weavix-shared/services/ptt.service';
import { isChrome, isEdge, isFirefox } from 'weavix-shared/utils/utils';
import { AnalyticsService } from './core/services/analytics.service';
import { AppService } from './core/services/app.service';
import { TranslationService } from './core/services/translation/translation.service';
import { TitleCasePipe } from '@angular/common';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [ TitleCasePipe ],
})
export class AppComponent implements OnInit, OnDestroy {
    appLoading: boolean;
    private destroyed$: Subject<boolean> = new Subject();

    pwaModal: Modal;
    ngswModal: Modal;
    permissionModal: Modal;
    pwaPrompt;

    unauthenticated: () => void;
    initialized: boolean;
    teamsApp = environment.teamsApp;

    constructor(
        private translationService: TranslationService,
        private router: Router,
        private route: ActivatedRoute,
        private matSnackBar: MatSnackBar,
        private alertsService: AlertService,
        private swUpdate: SwUpdate,
        private appRef: ApplicationRef,
        private appService: AppService,
        private pttService: PttService,
        private titleCasePipe: TitleCasePipe,
        _analyticsService: AnalyticsService,
    ) {
        this.pttService.prepare();
        this.appService.setAppDetails(fn => {
            this.unauthenticated = fn;
            this.checkLogin();
        });
        this.translationService.setInitialLanguage();

        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                let r = this.route.firstChild;
                while (r) {
                    if (r.firstChild) {
                        r = r.firstChild;
                    } else {
                        break;
                    }
                }
                handleRouteTelemetry(event.url);
            }
        });

        const handleRouteTelemetry = (url: string) => {
            const parts = url.split('?')[0].split('/').slice(1);
            if (parts[0] !== 'login') parts.splice(0, 2);
            const prettyName = this.titleCasePipe.transform(`Web-Radio ${parts.join(' ')}`);
            AnalyticsService.page(prettyName, url);
        };

        const promptDaUpdate = () => {
            this.alertsService.sendPrompt('pwa.update-title', 'pwa.update-refresh')
                .pipe(
                    switchMap(() => this.swUpdate.activateUpdate()),
                    takeUntil(this.destroyed$),
                )
                .subscribe(() => {
                    console.log('reloading due to pwa update');
                    document.location.reload();
                });
        };

        if (this.swUpdate.isEnabled) {
            this.swUpdate.versionUpdates
                .pipe(filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'))
                .subscribe(() => {
                    if (document.cookie.indexOf('e2e=true') !== -1) return;
                    promptDaUpdate();
                });

            const appIsStable$ = this.appRef.isStable.pipe(first(isStable => isStable === true));
            concat(appIsStable$, interval(3 * 60 * 60 * 1000)).subscribe(() => this.swUpdate.checkForUpdate());
        }

        LocalDeviceUtility.denied$.subscribe(() => {
            this.permissionModal = {
                isOpen: true,
                width: 600,
                actions: {
                    submit: { show: true, textKey: 'generics.ok' },
                },
                fullScreen: false,
                content: true,
                header: {
                    textKey: environment.teamsApp ? 'meeting-join.denied-permissions-header' : null,
                    textAlignment: 'left',
                },
            };
        });
    }

    permissionClose(_type: ModalActionType) {
        this.permissionModal = null;
    }

    get browserError() {
        if (this.teamsApp) return 'meeting-join.denied-teams';
        if (isChrome()) return 'meeting-join.denied-chrome';
        if (isFirefox()) return 'meeting-join.denied-firefox';
        if (isEdge()) return 'meeting-join.denied-edge';
        return null;
    }

    async ngOnInit() {
        this.appLoading = false;
        this.openAlertSubscription();
        this.openAppLoadingSubscription();
        this.initialized = true;
        this.checkLogin();
    }

    pwaClose(action: ModalActionType) {
        this.pwaModal = null;
        localStorage.setItem('pwa-prompted', 'true');
        if (action === ModalActionType.submit) {
            this.pwaPrompt.prompt();
        }
    }

    checkLogin() {
        if (this.initialized && this.unauthenticated) {
            this.pwaModal = null;
            this.unauthenticated();
        }
    }

    ngOnDestroy() {
        this.destroyed$.next(true);
        this.destroyed$.complete();
    }

    showSnackBar(res: SnackBar) {
        const sb = res.icon ?
        this.matSnackBar.openFromComponent(SnackBarComponent, {
            duration: res.timeout,
            verticalPosition: 'bottom',
            panelClass: res.cssClass,
            data: res,
        }) :
        this.matSnackBar.open(
            res.message,
            res.action, {
                duration: res.timeout,
                verticalPosition: 'bottom',
                panelClass: res.cssClass,
        });
        if (!res.response) return;
        sb.afterDismissed()
            .pipe(takeUntil(this.destroyed$))
            .subscribe(() => {
                res.response.next();
        });
    }

    private openAlertSubscription(): void {
        this.alertsService.alert$
            .pipe(takeUntil(this.destroyed$))
            .subscribe(res => this.showSnackBar(res));
    }

    private openAppLoadingSubscription(): void {
        AlertService.appLoadingSubject
            .pipe(takeUntil(this.destroyed$))
            .subscribe(res => {
                this.appLoading = res;
            });
    }
}
