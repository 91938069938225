<div *ngIf="showLabel && label" class="label-container">
    <span class="input-label" [class.required]="required" *ngIf="label">{{doTranslate ? (label | translate) : label }}</span>
    <app-tooltip *ngIf="tooltip" [options]="tooltip" [doTranslate]="doTranslate"></app-tooltip>
</div>

<mat-form-field
    *ngIf="selectedItems$ | async as selectedItems"
    class="w100p app-chip-list"
    [floatLabel]="floatLabel ? 'auto' : 'never'"
    [ngClass]="{'dark': dark, 'disabled' : isDisabled}"
>
    <mat-chip-list
        #chipList
        [required]="required"
    >

        <app-chip
            *ngFor="let item of selectedItems"
            [chip]="item"
            [displayKey]="displayKey"
            [doTranslate]="doTranslate"
            [invalid]="item.invalid"
            [removable]="!isReadOnly"
            (removeOutput)="remove($event)"
        ></app-chip>

        <input
            #input
            type="text"
            [formControl]="inputCtrl"
            [matChipInputFor]="chipList"
            [matAutocomplete]="chipAuto"
            [id]="inputId"
            [ngClass]="{ 'disabled' : isDisabled }"
            (focus)="inputFocus.emit($event)"
        >
        
        <span *ngIf="placeholder && !inputCtrl.value && selectedItems.length === 0" class="placeholder">{{ placeholder | translate }}</span>

    </mat-chip-list>

    <mat-autocomplete
        #chipAuto
        autoActiveFirstOption
        [displayWith]="displayFn"
        [class]="autocompleteClassList"
        (optionSelected)="addItem($event)"
        (closed)="handleAutocompleteClosed()"
    >
        <ng-container *ngIf="filteredItems$ | async as filteredItems">
            <mat-option *ngIf="filteredItems.length < 1" disabled>
                {{ (emptyOptionText ? emptyOptionText : 'generics.empty-options') | translate }}
            </mat-option>
            <mat-option
                appPreventBlur
                disableRipple
                *ngFor="let item of filteredItems; trackBy: trackChipFn"
                [ngClass]="{'hidden': item.hidden}"
                [value]="item"
                [disabled]="item.isDisabled && item.isDisabled(item) || !removeSelected && (item | appChipIsSelected:selectedItems)"
                (mousedown)="$event.preventDefault()"
            >
                <span *ngIf="!removeSelected && (item | appChipIsSelected:selectedItems)"><i class="fas fa-check mR5"></i></span>
                <span *ngIf="doTranslate">{{item | appChipDisplay:displayKey | translate}}</span>
                <span *ngIf="!doTranslate">{{item | appChipDisplay:displayKey}}</span>
            </mat-option>
            <mat-option
                *ngIf="canAdd && inputCtrl.value && filteredItems.length <= canAddLimit"
                appPreventBlur
                disabled
                class="add-new-item"
                (click)="addNewItem(inputCtrl.value)"
            >
                <span class="show w100p h100p cPointer">{{ (addNewItemLabel ? addNewItemLabel : 'shared.generics.add-item') | translate:{item:inputCtrl.value} }}</span>
            </mat-option>
        </ng-container>
        <mat-option *ngIf="(hasMoreOptions$ | async) && !inputCtrl.value" appPreventBlur disabled>
            {{ 'shared.generics.search-for-more-options' | translate }}
        </mat-option>
    </mat-autocomplete>
</mat-form-field>

<div *ngIf="showErrors" class="error-container">
    <mat-error *ngIf="formControl?.hasError('required') && formControl?.touched">{{ 'formValidation.required' | translate:{ label: label | translate } }}</mat-error>
    <mat-error *ngIf="formControl?.hasError('invalidFacilitiesWorkflow')">{{ 'configuration.workflows.invalid-facilities' | translate }}</mat-error>
    <mat-error *ngIf="formControl?.hasError('invalidFacilitiesForm')">{{ 'configuration.forms.invalid-facilities' | translate }}</mat-error>
</div>

