import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Chip } from '../chip-list.component';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { CommonModule } from '@angular/common';
import { IconComponent } from 'components/icon/icon.component';
import { TranslateModule } from '@ngx-translate/core';
import { ChipDisplayPipe } from '../chip-display.pipe';

@Component({
    selector: 'app-chip',
    templateUrl: './chip.component.html',
    styleUrls: ['./chip.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        CommonModule,
        MatChipsModule,
        TranslateModule,

        ChipDisplayPipe,

        IconComponent,
    ],
})
export class ChipComponent<T extends Chip | string> {
    @Input() chip: T;
    @Input() displayKey: 'id' | 'name' = 'name';
    @Input() selectable = false;
    @Input() disabled = false;
    @Input() removable = true;
    @Input() doTranslate = true;
    @Input() invalid = false;

    @Output() removeOutput = new EventEmitter<T>();

    handleRemove() {
        if (this.removable && !this.disabled) {
            this.removeOutput.emit(this.chip);
        }
    }
}
