
<div #filter class="filter-container text-input" appClickOutside (clickOutside)="clickedOutside()">
    <app-loading [numBars]="1" [loading]="!curTree || !allFolders"></app-loading>
    <div *ngIf="label" class="label-container">
        <span class="input-label" *ngIf="label">{{translate ? (label | translate) : label }}{{required ? '*' : ''}}</span>
    </div>
    <mat-form-field class="w100p" (click)="searchFocused()" [floatLabel]="floatLabel ? 'always' : 'never'">
        <mat-chip-list #chipList [required]="required">
            <app-chip *ngFor="let folder of selectedFilterFolders" [chip]="folder" [doTranslate]="translate"
                [disabled]="readonly" [removable]="!readonly" [selectable]="false" (removeOutput)="remove($event)"></app-chip>
            <input #search [(ngModel)]="searchQuery" *ngIf="!didItReachMax()"
                [matChipInputFor]="chipList"
                (input)="searchUpdated()"
                (focus)="searchFocused()"
                (blur)="blur()"
                [readonly]="readonly">
        </mat-chip-list>
        <mat-error *ngIf="touched">{{ 'folders.required' | translate }}</mat-error>
    </mat-form-field>

    <div appPreventBlur class="tree-container" *ngIf="showFolders">
        <app-loading [loading]="loading"></app-loading>
        <ng-container *ngIf="showTree">
            <div class="current" [ngClass]="folderHovered === true ? 'hover' : ''" *ngIf="canGoBack()">
                <button type="button" class="back icon" (click)="back()"><i class="button fas fa-chevron-left"></i></button>
                <div class="name">{{curTree.name}}</div>
            </div>
            <div class="folders-container">
                <div>
                    <div class="folder-row" [ngClass]="folder.hovered === true ? 'hover' : ''" *ngFor="let folder of curTree.children; let i = index;" [attr.id]="'folder' + folder.id">
                        <i class="selected fas fa-check" *ngIf="folder.selected === true"></i>
                        <button type="button" class="folder" (click)="add(folder, i)" [disabled]="folder.selected === true">{{folder.name}}</button>
                        <div class="next">
                            <button type="button" class="icon" *ngIf="folder.children.length > 0" (click)="next(folder)"><i class="button fas fa-chevron-right"></i></button>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="!showTree">
            <div class="current">
                <div class="results pL10">{{'searchResults' | translate}} <span class="query">{{searchQuery}}</span></div>
            </div>
            <div class="folders-container">
                <div class="folder-row" [ngClass]="folder.hovered === true ? 'hover' : ''" *ngFor="let folder of searchResults; let i = index;" [attr.id]="'folder' + folder.id">
                    <i class="selected fas fa-check" *ngIf="folder.selected === true"></i>
                    <button type="button" class="folder" (click)="add(folder, i)" [disabled]="folder.selected === true">{{folder.name}}</button>
                    <div class="next">
                        <button type="button" class="icon" *ngIf="folder.children.length > 0" (click)="next(folder)"><i class="button fas fa-chevron-right"></i></button>
                    </div>
                </div>
                <div class="no-data" *ngIf="searchResults.length === 0">
                    {{'no-data' | translate}}
                </div>
            </div>
        </ng-container>
    </div>
</div>
