<div class="snack-bar flexRow jcFS aiC gap10" [ngClass]="data?.containerClass">
    <div *ngIf="data.icon" class="snack-bar-icon">
        <app-icon [icon]="data.icon"></app-icon>
    </div>
    <div class="snack-bar-message">{{ data.message }}</div>
    <button *ngIf="data.buttonProps" class="snack-bar-action" (click)="close(true)">
        <app-icon class="snack-bar-icon" [faIcon]="data.buttonProps.icon.faIcon"/>
        {{ data.buttonProps.label | translate }}
    </button>
    <button (click)="close(false)" class="snack-bar-dismiss"> 
        <app-icon [faIcon]="'far fa-xmark'"/>
    </button>
</div>
