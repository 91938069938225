import { ComplexPersonCondition, ConditionalKeys, PersonFacilityPredicate, PersonLike, PersonMatcher, SimplePersonCondition } from '@weavix/models/src/person/person-matcher';
import { isEmpty, isNil } from 'lodash';

function matchesAtLeastOne(list: string[] | undefined, values: (string | undefined)[] | undefined): boolean {
    if (!list) return false;
    if (!values) return false;
    return list.some(listValue => values.includes(listValue));
}

export function evaluateCondition(
    condition: SimplePersonCondition,
    person: PersonLike,
    facilityLookup: PersonFacilityPredicate,
    facilityMatch = true): boolean {
    // root level facility specification must match for any non-exact person match
    if (!facilityMatch && (condition.key !== ConditionalKeys.People || condition.negate)) return false;

    function evaluateStraight() {
        let value: string | string[] | undefined | PersonFacilityPredicate;
        switch (condition.key) {
            case ConditionalKeys.People: value = person.id; break;
            case ConditionalKeys.PersonCompany: value = person.companyId; break;
            case ConditionalKeys.PersonCraft: value = person.crafts; break;
            case ConditionalKeys.PersonTags: value = person.tags; break;
            case ConditionalKeys.PersonSites: value = (facilityId: string) => {
                if (person.group === 'global-admin') return true;
                return facilityLookup(facilityId, person);
            }; break;
        }
        if (isNil(value)) return false;
        if (typeof value === 'function') return condition.value?.some(v => (value as PersonFacilityPredicate)(v));
        if (Array.isArray(value)) return condition.value?.some(v => (value as string[]).includes(v));
        return condition.value?.some(v => value === v);
    }

    return evaluateStraight() ? !condition.negate : !!condition.negate;
}

export function matchPerson(matcher: PersonMatcher, person: PersonLike, isPersonOnFacility: PersonFacilityPredicate) {
    // person still exists but userId removed when Person is removed from account
    // Temporary support users should not be auto-added to channels.
    if (!person?.userId || person.supportAccess) return false;

    function standardMatch() {
        if (matcher.defaultEmpty &&
            isEmpty(matcher.people) &&
            isEmpty(matcher.peopleCrafts) &&
            isEmpty(matcher.peopleTags) &&
            isEmpty(matcher.companies) &&
            isEmpty(matcher.facilityIds) &&
            isEmpty(matcher.excludedTags) &&
            isEmpty(matcher.excludedPeople) &&
            isEmpty(matcher.excludedCompanies) &&
            isEmpty(matcher.excludedCrafts) &&
            isEmpty(matcher.excludedFacilityIds)
        ) {
            return false;
        }
        if (matchesAtLeastOne(matcher.excludedPeople, [person.id]) ||
            matchesAtLeastOne(matcher.excludedTags, person.tags) ||
            matchesAtLeastOne(matcher.excludedCrafts, person.crafts) ||
            matchesAtLeastOne(matcher.excludedCompanies, [person.companyId]) ||
            matchesAtLeastOne(matcher.excludedFacilityIds, person.facilityIds) ||
            (!isEmpty(matcher.peopleCrafts) && !matchesAtLeastOne(matcher.peopleCrafts, person.crafts)) ||
            (!isEmpty(matcher.peopleTags) && !matchesAtLeastOne(matcher.peopleTags, person.tags)) ||
            (!isEmpty(matcher.companies) && !matchesAtLeastOne(matcher.companies, [person.companyId])) ||
            (!isEmpty(matcher.facilityIds) && !matchesAtLeastOne(matcher.facilityIds, person.facilityIds))
        ) {
            return false;
        }
        return true;
    }
    
    function advancedMatch(condition: ComplexPersonCondition,
            facilityMatch: boolean,
            def: boolean): boolean {
        const enabledConditions = condition.conditions.filter(v => v.enabled !== false);
        if (!enabledConditions.length) return def && facilityMatch;
        return enabledConditions[condition.type === 'or' ? 'some' : 'every'](v => {
            if ((v as SimplePersonCondition).key) {
                return evaluateCondition(v as SimplePersonCondition, person, isPersonOnFacility, facilityMatch);
            } else {
                const defaultReturn = condition.type === 'and';
                return advancedMatch(v as ComplexPersonCondition, facilityMatch, defaultReturn);
            }  
        });
    }
    
    function isFacilityMatch() {
        if (person.group !== 'global-admin') {
            if (matcher.facilityIds && !isEmpty(matcher.facilityIds) && !matcher.facilityIds.some(facilityId => isPersonOnFacility(facilityId, person))) {
                return false;
            }
            if (matcher.excludedFacilityIds && !isEmpty(matcher.excludedFacilityIds) && matcher.excludedFacilityIds.some(facilityId => isPersonOnFacility(facilityId, person))) {
                return false;
            }
        }
        return true;
    }

    if (matcher.advancedCondition?.enabled) {
        const facilityMatch = isFacilityMatch();
        return advancedMatch(matcher.advancedCondition, facilityMatch, true);
    }

    if (matcher.standardConditions) {
        const facilityMatch = isFacilityMatch();
        return advancedMatch({
            conditions: matcher.standardConditions,
            type: 'and',
            enabled: true,
        }, facilityMatch, true);
    }

    if (!isEmpty(matcher.people)) {
        const personInList = matcher.peopleSet ? matcher.peopleSet.has(person.id!) : matcher.people?.includes(person.id!);
        if (person.id && personInList && !matchesAtLeastOne(matcher.excludedPeople, [person.id])) return true;
        if (isEmpty(matcher.facilityIds) && isEmpty(matcher.peopleCrafts) && isEmpty(matcher.peopleTags) && isEmpty(matcher.companies)) return false;
    }

    if (!isFacilityMatch()) return false;
    return standardMatch();
}
