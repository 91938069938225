import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AccountPersonStateService } from './account-person-state.service';
import { AccountService } from './account.service';
import { AlertMaydayService } from './alert-mayday.service';
import { AlertStateService } from './alert-state.service';
import { BeaconStateService } from './beacon-state.service';
import { CompanyStateService } from './company-state.service';
import { CompanyService } from './company.service';
import { ConfinedSpaceService } from './confined-space.service';
import { FacilityService } from './facility.service';
import { CustomFloorPlanStateService, FloorPlanStateService } from './floor-plan-state.service';
import { FloorPlanService } from './floor-plan.service';
import { CustomGeofenceStateService, GeofenceStateService } from './geofence-state.service';
import { GeofenceService } from './geofence.service';
import { ItemStateService } from './item-state.service';
import { ItemService } from './item.service';
import { NetworkLocationService } from './network-location.service';
import { PersonStateService } from './person-state.service';
import { PersonService } from './person.service';
import { ProfileService } from './profile.service';
import { StateServiceBase } from './state-base.service';
import { StructureStateService } from './structure-state.service';
import { StructureService } from './structure.service';
import { TrackingCircleStateService } from './tracking-circle-state.service';
import { WaltStateService } from './walt-state.service';
import { WaltService } from './walt.service';
import { WifiRouterStateService } from './wifi-router-state.service';
import { WranglerStateService } from './wrangler-state.service';


export enum MapStateType {
    Alert = 'alert',
    Beacon = 'beacon',
    Company = 'company',
    FloorPlan = 'floorPlan',
    Geofence = 'geofence',
    Item = 'item',
    Person = 'person',
    Structure = 'structure',
    TrackingCircle = 'trackingCircle',
    Walt = 'walt',
    WifiRouter = 'wifiRouter',
    Wrangler = 'wrangler',
}

export type MapStateStartConfig = {
    [key in MapStateType]?: MapStateActions
};

export type MapStates = {
    [key in MapStateType]?: {
        type?: new(...args) => any;
        data?: any[];
        updateSub$?: Subject<any[]>;
    }
};

export interface MapStateActions {
    load: boolean;
    update: boolean;
    draw?: boolean;
}

@Injectable({
    providedIn: 'root',
})
export class MapStateService {
    private allStateServices: Map<MapStateType, StateServiceBase> = new Map();
    private servicesToLoad: StateServiceBase[];
    private servicesToUpdate: StateServiceBase[];

    public beacon: BeaconStateService;
    public wrangler: WranglerStateService;
    public trackingCircle: TrackingCircleStateService;
    public geofence: GeofenceStateService | CustomGeofenceStateService;
    public item: ItemStateService;
    public person: AccountPersonStateService | PersonStateService;
    public company: CompanyStateService;
    public alert: AlertStateService;
    public floorPlan: FloorPlanStateService | CustomFloorPlanStateService;
    public structure: StructureStateService;
    public walt: WaltStateService;
    public wifiRouter: WifiRouterStateService;

    constructor(
        private accountService: AccountService,
        private facilityService: FacilityService,
        private profileService: ProfileService,
        private geofenceService: GeofenceService,
        private itemService: ItemService,
        private confinedSpaceService: ConfinedSpaceService,
        private personService: PersonService,
        private companyService: CompanyService,
        private waltService: WaltService,
        private alertMaydayService: AlertMaydayService,
        private floorPlanService: FloorPlanService,
        private structureService: StructureService,
        private networkLocationService: NetworkLocationService,
    ) { }

    /**
     * Starts state services. Loads data and subscribes to mqtt events
     */
    async start(component: any, config?: MapStateStartConfig, tags?: string[], services?: MapStates, setFacilityId?: string) {
        this.initializeServices(services);
        const accountId = this.accountService.getAccountId();
        const facilityId: string = setFacilityId !== undefined ? setFacilityId : this.facilityService.getCurrentFacility()?.id;

        this.servicesToUpdate = this.getServicesFromConfig(config, a => a.update);
        await Promise.all(this.servicesToUpdate.map(s => s.startSubscriptions(component, accountId, facilityId, tags)));

        this.servicesToLoad = this.getServicesFromConfig(config, a => a.load);
        await Promise.all(this.servicesToLoad.map(s => s.loadData(component, accountId, facilityId, tags)));
    }

    /**
     * Initialize state services.
     */
    initializeServices(services?: MapStates) {
        this.beacon = new BeaconStateService(this.facilityService, this.profileService);
        this.wrangler = new WranglerStateService(this.facilityService, this.profileService);
        this.trackingCircle = new TrackingCircleStateService(this.facilityService, this.profileService);
        this.geofence = services?.geofence
            ? new CustomGeofenceStateService(services.geofence.data, services.geofence.updateSub$)
            : new GeofenceStateService(this.geofenceService, this.profileService);
        this.item = new ItemStateService(this.itemService, this.confinedSpaceService, this.profileService);
        this.person = services?.person?.type
            ? new services.person.type(this.personService, this.facilityService, this.profileService)
            : new AccountPersonStateService(this.personService, this.facilityService, this.profileService);
        this.company = new CompanyStateService(this.companyService);
        this.alert = new AlertStateService(this.alertMaydayService, this.profileService);
        this.structure = new StructureStateService(this.structureService, this.profileService);
        this.floorPlan = services?.floorPlan
            ? new CustomFloorPlanStateService(services.floorPlan.data, services.floorPlan.updateSub$)
            : new FloorPlanStateService(this.floorPlanService, this.profileService);
        this.walt = new WaltStateService(this.waltService, this.personService, this.profileService);
        this.wifiRouter = new WifiRouterStateService(this.networkLocationService, this.profileService);
        this.allStateServices.set(MapStateType.Beacon, this.beacon);
        this.allStateServices.set(MapStateType.Wrangler, this.wrangler);
        this.allStateServices.set(MapStateType.TrackingCircle, this.trackingCircle);
        this.allStateServices.set(MapStateType.Geofence, this.geofence);
        this.allStateServices.set(MapStateType.Item, this.item);
        this.allStateServices.set(MapStateType.Person, this.person);
        this.allStateServices.set(MapStateType.Company, this.company);
        this.allStateServices.set(MapStateType.Alert, this.alert);
        this.allStateServices.set(MapStateType.FloorPlan, this.floorPlan);
        this.allStateServices.set(MapStateType.Structure, this.structure);
        this.allStateServices.set(MapStateType.Walt, this.walt);
        this.allStateServices.set(MapStateType.WifiRouter, this.wifiRouter);
    }

    private getServicesFromConfig(config?: MapStateStartConfig, filter?: (a: MapStateActions) => boolean) {
        const services = config
            ? Object.keys(config).filter((k: MapStateType) => !filter || filter(config[k])).map((k: MapStateType) => this.allStateServices.get(k))
            : Array.from(this.allStateServices.values());

        return services;
    }

    async restart(component, config?: MapStateStartConfig, tags?: string[], services?: MapStates) {
        await this.stop();
        await this.start(component, config, tags, services);
    }

    /**
     * Subscribes to mqtt events
     */
    async startSubscriptions(component: any, tags?: string[]) {
        const accountId = this.accountService.getAccountId();
        const facility = this.facilityService.getCurrentFacility();
        await Promise.all(this.servicesToUpdate.map(x => x.startSubscriptions(component, accountId, facility?.id, tags)));
    }

    /**
     * Unsubscribes from mqtt events
     */
    async stopSubscriptions() {
        await Promise.all(this.servicesToUpdate.map(x => x.stopSubscriptions()));
    }

    /**
     * Stops state services. Unsubscribes from mqtt events and clears data
     */
    async stop() {
        await Promise.all(Array.from(this.allStateServices.values()).map(x => x.stop()));
    }
}
