import { AfterViewInit, Component, ElementRef, EventEmitter, forwardRef, Injector, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NgControl, NG_VALUE_ACCESSOR, FormsModule } from '@angular/forms';
import { ThemeService } from 'weavix-shared/services/themeService';
import { TooltipOptions } from 'weavix-shared/models/tooltip.model';
import { AutoUnsubscribe } from 'weavix-shared/utils/utils';
import { CommonModule } from '@angular/common';
import { TooltipComponent } from 'components/tooltip/tooltip.component';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { TranslateModule } from '@ngx-translate/core';

@AutoUnsubscribe()
@Component({
    selector: 'app-checkbox',
    templateUrl: './checkbox.component.html',
    styleUrls: ['./checkbox.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CheckboxComponent),
            multi: true,
        },
    ],
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        MatCheckboxModule,
        MatFormFieldModule,
        TranslateModule,

        TooltipComponent,
    ],
})
export class CheckboxComponent implements AfterViewInit, OnChanges, ControlValueAccessor {
    @Input() inputId: string = 'inputId';
    @Input() label: string = '';
    @Input() floatedLabel: string;
    @Input() tooltip: TooltipOptions;
    @Input() required: boolean = false;
    @Input() disabled: boolean = false;
    @Input() translate: boolean = true;
    @Input() checked: boolean;
    @Input() autofocus: boolean = false;
    @Input() showErrors: boolean = true;
    @Input() labelPosition: 'before' | 'after' = 'before';
    @Input() markerShape: 'square' | 'circle' = 'square';
    @Input() noVisibleWrapper: boolean = false;
    @Input() floatedLabelTooltip: TooltipOptions;
    @Output() valueChanged: EventEmitter<boolean> = new EventEmitter();

    @ViewChild('input', { static: false }) inputElementRef: ElementRef;

    lightTheme: boolean;
    formControl?: NgControl;

    constructor(
        private injector: Injector,
    ) {
        this.lightTheme = ThemeService.getLightTheme();
    }

    ngOnChanges() {
        try { this.formControl = this.injector.get(NgControl, null); } catch (e) {
            console.error('CheckboxComponent could not inject NgControl.', e);
        }
    }

    ngAfterViewInit() {
        if (this.autofocus) this.focus();
    }

    setChecked(checked: boolean) {
        this.checked = checked;
        if (this.onChange) this.onChange(checked);
        this.valueChanged.emit(checked);
    }

    focus() {
        if (this.inputElementRef) this.inputElementRef.nativeElement.focus();
    }

    // Form control interface fns
    onChange = (x: boolean) => {};

    onTouched = () => {};

    async writeValue(checked: boolean) {
        this.checked = checked;
    }

    registerOnChange(fn: (checked: boolean) => void) { this.onChange = fn; }

    registerOnTouched(fn: () => void) { this.onTouched = fn; }
    // END Form control

}
