import { Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import { TeamsService } from 'weavix-shared/services/teams.service';
import { AutoUnsubscribe, sleep } from 'weavix-shared/utils/utils';
import { TranslateModule } from '@ngx-translate/core';
import { Modal, ModalActionType, defaultModal } from 'components/modal/modal.model';
import { environment } from 'environments/environment';
import { CommonModule } from '@angular/common';
import { IconComponent } from 'components/icon/icon.component';

@AutoUnsubscribe()
@Component({
    selector: 'app-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        TranslateModule,
        IconComponent,
    ],
})
/**
 * ngOnChanges does not run when created dynamically.
 * Any inputs need getter/setters to manage changes manually
 */
export class ModalComponent {
    private modalInputBacker: Modal;
    @Input()
    set modalInput(value: Modal) {
        this.modalInputBacker = value;
        this.updateModalInput(value);
        if (!value.doNotAutoFocus) this.focusFirstElement(1000);
    }
    get modalInput() {
        return this.modalInputBacker;
    }
    @Input() downloadUrl: string;
    @Input() outerTitle: string;
    @Input() showClose: boolean = !this.teamsService.isTeamsApp();
    // when false modal fades out and cannot be re-used on submit
    @Input() alwaysOpen: boolean = environment.consoleApp;

    @Output() modalCloseOutput: EventEmitter<ModalActionType> = new EventEmitter();

    @ViewChild('modal') modal: ElementRef;

    modalActionType = ModalActionType;
    closing = false;

    buttonHovers: { [id: string]: boolean } = {};

    constructor(
        public teamsService: TeamsService,
    ) { }

    @HostListener('document:keydown.escape')
    onKeydownHandler() {
        this.modalCloseOutput.emit(ModalActionType.cancel);
    }

    @HostListener('document:keydown.tab')
    checkTabFocus() {
        this.focusFirstElement(1);
    }

    @HostListener('document:keydown.shift.tab')
    checkShiftTabFocus() {
        this.focusFirstElement(1, true);
    }

    get textDivStyle() {
        return {'text-align': this.teamsService.isTeamsApp() ? 'left' : this.modalInput?.textAlignment, ...this.modalInput.textStyle};
    }

    /**
     * @param sleepTime Use for init to give time for any content to render if calls had to be made
     * and then passing 1 for normal tab checks for document tab to update current element
     */
    async focusFirstElement(sleepTime: number, backwards: boolean = false) {
        await sleep(sleepTime);
        if (!this.modal) return;
        const div = this.modal.nativeElement as HTMLDivElement;
        const isInModal = div.contains(document.activeElement);
        if (!isInModal) {
            if (backwards) {
                const elements = div.querySelectorAll('input, button');
                (elements[elements.length - 1] as HTMLElement)?.focus();

            } else {
                const focusElement = div.querySelector('input, button') as HTMLElement;
                focusElement?.focus();
            }
        }
    }

    updateModalInput(modal: Modal) {
        this.modalInputBacker = _.merge(_.cloneDeep(defaultModal), modal);
    }

    async handleModalClose(action: ModalActionType): Promise<void> {
        if (!this.alwaysOpen) {
            this.closing = true;
            setTimeout(() => this.modalCloseOutput.emit(action), 400);
        } else {
            this.modalCloseOutput.emit(action);
        }

    }

}
