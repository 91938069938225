import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ThemeService } from 'weavix-shared/services/themeService';
import { actionTypeToTranslationKey, BulkEdit, BulkEditAction, reportActionTypeToTranslationKey, TableHeaderAction, TableHeaderOptions, TableHeaderReportAction, TableRow } from 'weavix-shared/models/table.model';
import { ProfileService } from 'weavix-shared/services/profile.service';
import { TranslationService } from 'weavix-shared/services/translation.service';
import { AutoUnsubscribe } from 'weavix-shared/utils/utils';
import { ButtonGroupComponent, ButtonGroupOption } from '../../button-group/button-group.component';
import { TableService } from '../table.service';
import { CommonModule } from '@angular/common';
import { IconComponent } from 'components/icon/icon.component';
import { InputComponent } from 'components/input/input.component';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { TranslateModule } from '@ngx-translate/core';

@AutoUnsubscribe()
@Component({
    selector: 'app-table-header',
    templateUrl: './table-header.component.html',
    styleUrls: ['./table-header.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        MatTooltipModule,
        TranslateModule,

        ButtonGroupComponent,
        IconComponent,
        InputComponent,
    ],
})
export class TableHeaderComponent implements OnInit, OnChanges {
    @Input() options: TableHeaderOptions;
    @Input() folderId: string;
    @Input() selectedKeys: {[key: string]: TableRow};
    @Input() selectedFolderKeys: {[key: string]: TableRow};
    @Output() bulkEditOutput = new EventEmitter<BulkEdit>();
    @Output() bulkMoveOutput = new EventEmitter<void>();
    @Output() exportReportOutput = new EventEmitter<void>();

    actionTypeToTranslationKey = actionTypeToTranslationKey;
    reportActionTypeToTranslationKey = reportActionTypeToTranslationKey;

    bulkEdits: BulkEdit[] = [];

    loading: boolean = false;
    lightTheme: boolean;

    constructor(
        public tableService: TableService,
        private profileService: ProfileService,
        private translationService: TranslationService,
    ) {}

    ngOnInit() {
        this.loading = true;
        this.lightTheme = ThemeService.getLightTheme();
        this.loading = false;
        if (this.options.bulkEdits && this.options.bulkEdits.length) this.bulkEdits = this.options.bulkEdits;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.options && changes.options.currentValue) {
            this.bulkEdits = this.options.bulkEdits ?? [];
        }
    }

    getActionButtonText() {
        const selectedKeyCount = this.getSelectedKeyCount();
        const count = selectedKeyCount > 0 ? ` (${selectedKeyCount}) ${this.translationService.getImmediate('selected')}` : '';
        return `${this.translationService.getImmediate('table.actions')}${count}`;
    }

    getActionEditOptions(): ButtonGroupOption[] {
        const options: {display: string, onClick: () => void, icon?: string}[] = [];
        this.bulkEdits.forEach(edit => {
            if (this.hasBulkEditPermission(edit)) {
                options.push({
                    display: edit.title,
                    icon: edit.icon,
                    onClick: () => edit.isReportAction ? this.exportReportOutput.emit() : this.bulkEditOutput.emit(edit),
                });
            }
        });


        if (this.options.folderType && this.canBulkMove()) {
            options.push({
                display: 'table.move',
                icon: 'fas fa-grip-horizontal',
                onClick: () => this.bulkMoveOutput.emit(),
            });
        }
        return options;
    }

    private hasBulkEditPermission(edit: BulkEdit) {
        if (edit.action === BulkEditAction.delete) {
            return Object.values(this.selectedKeys).every(v => this.hasEditPermission(v.original, true));
        }
        if (edit.permission) {
            return Object.values(this.selectedKeys).every(v => typeof edit.permission === 'function'
                ? edit.permission(v.original)
                : this.hasEditPermission(v.original));
        }

        return true;
    }

    private canBulkMove() {
        return Object.values(this.selectedKeys).every(v => this.hasEditPermission(v.original));
    }

    hasPermission(action: TableHeaderAction | TableHeaderReportAction): boolean {
        return this.profileService.hasAddPermission(
            action.permission,
            action.facilityId ?? this.options.facilityId,
            ('multiFacility' in action ? action.multiFacility : undefined) ?? !!this.options.facilityIds,
            this.folderId);
    }

    private hasEditPermission(row, allFacilities = false) {
        const folderId = row.folderId;
        const facilityId = this.options.facilityId ?? (this.options.facilityIds ? this.options.facilityIds(row) : null);
        return this.profileService.hasFacilitiesPermission(
            this.options.editPermissionAction,
            facilityId, folderId, allFacilities,
        );
    }

    getSelectedKeyCount() {
        return Object.keys(this.selectedKeys).length + Object.keys(this.selectedFolderKeys).length;
    }
}
