import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, forwardRef, Injector, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { NgControl, NG_VALUE_ACCESSOR, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ThemeService } from 'weavix-shared/services/themeService';
import { TooltipOptions } from 'weavix-shared/models/tooltip.model';
import { CountryCode, parsePhoneNumber } from 'libphonenumber-js';
import { cloneDeep } from 'lodash';
import { AutocompleteOption } from './input.model';
import { environment } from 'environments/environment';
import { CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { IconComponent } from 'components/icon/icon.component';
import { TranslateModule } from '@ngx-translate/core';
import { TooltipComponent } from 'components/tooltip/tooltip.component';
import { Ng2TelInput, SelectedCountryData } from 'components/ng2-tel-input/ng2-tel-input.directive';
import { ClickOutsideDirective } from 'weavix-shared/directives/click-outside.directive';
import { MatInputModule } from '@angular/material/input';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

export interface AppendAction {
    textKey: string;
    disabled?: boolean;
    action: () => void;
}

@Component({
    selector: 'app-input',
    templateUrl: './input.component.html',
    styleUrls: ['./input.component.scss', './input-teams.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => InputComponent),
            multi: true,
        },
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatMenuModule,
        MatInputModule,
        TranslateModule,

        IconComponent,
        TooltipComponent,
        Ng2TelInput,
        ClickOutsideDirective,
    ],
})
export class InputComponent implements AfterViewInit, OnChanges {
    DEFAULT_MAX_LENGTH = 524288;
    
    @Input() inputId: string = 'inputId';
    @Input() prependIcon: string;
    @Input() type: string = 'text';
    @Input() placeholder: string = '';
    @Input() label: string = '';
    @Input() isShowLabel: boolean = true;
    @Input() required: boolean = false;
    @Input() alwaysShowRequiredIndicator: boolean = false;
    @Input() isDisabled: boolean = false;
    @Input() isClickable: boolean = false;
    @Input() isObfuscated: boolean = false;
    @Input() doTranslate: boolean = true;
    @Input() value: string;
    @Input() autofocus: boolean = false;
    @Input() autocompleteOptions: AutocompleteOption[];
    @Input() hideAutoComplete: boolean = false;
    @Input() bottomBorderColor: string;
    @Input() showErrors: boolean = true;
    @Input() errorClass: string;
    @Input() readonly: boolean;
    @Input() tooltip: TooltipOptions;
    @Input() dark: boolean = false; // DK_GRAY background
    @Input() light: boolean = false; // WHITE background
    @Input() backgroundColor: string;
    @Input() appendIcon: string;
    @Input() appendIconClass: string;
    @Input() appendActions: AppendAction[];
    @Input() acceptableFileTypes: string = '';
    @Input() width: string;
    @Input() maxLength: number = this.DEFAULT_MAX_LENGTH;
    @Input() maxNumber: number;
    @Input() minNumber: number;
    @Input() isForceNumeric: boolean = false;
    /** The tabindex of the selected country button for phone input. */
    @Input() phoneCountryTabIndex = 0;
    @Output() inputBlur = new EventEmitter<string>();
    @Output() inputFocused = new EventEmitter<void>();
    @Output() valueChanged = new EventEmitter<string>();
    @Output() fileValueChanged = new EventEmitter<File>();
    @Output() inputRef = new EventEmitter<ElementRef<HTMLInputElement>>();

    @ViewChild('input') inputElementRef?: ElementRef<HTMLInputElement>;

    readonly teamsApp = environment.teamsApp;

    readonly showDropDown$ = new BehaviorSubject(false);
    readonly lightTheme: boolean;
    formControl?: NgControl;

    private readonly displayValueSubject = new BehaviorSubject(''); // untrimmed value
    public readonly displayValue$ = this.displayValueSubject.pipe(
        map(val => {
            if (this.isObfuscated) return '********';
            else return val;
        }),
    );

    private countryCode: string;
    readonly focused$ = new BehaviorSubject(false);
    readonly isIeOrEdge = /msie\s|trident\/|edge\/|edg\//i.test(window.navigator.userAgent);

    readonly filteredAutocompleteOptions$ = new BehaviorSubject<AutocompleteOption[]>([]);

    constructor(
        private readonly injector: Injector,
        private readonly cdr: ChangeDetectorRef,
    ) {
        this.lightTheme = ThemeService.getLightTheme();
    }

    ngOnChanges(changes: SimpleChanges) {
        try { this.formControl = this.injector.get(NgControl, null); } catch (e) {
            console.error('InputComponent could not inject NgControl.', e);
        }
        if (changes.value?.previousValue !== changes.value?.currentValue) {
            this.sanitizeValue(changes.value.currentValue);
        }
        if (changes['isObfuscated']) {
            this.displayValueSubject.next(this.displayValueSubject.value);
        }
    }

    ngAfterViewInit() {
        this.filteredAutocompleteOptions$.next(this.autocompleteOptions?.slice(0, 5) ?? []);
        if (this.autofocus) this.focus();
        if (this.inputElementRef) this.inputRef.emit(this.inputElementRef);
    }

    setValue(value: string) {
        this.sanitizeValue(value);

        this.filterAutocompleteOptions();
        if (this.onChange) this.onChange(this.value);
        this.valueChanged.emit(this.value);
    }

    onKeyPress(event: KeyboardEvent) {
        if (this.type === 'number' || this.isForceNumeric) {
            if (event.code.indexOf('Numpad') === -1 && event.code.indexOf('Digit') === -1 && event.code.indexOf('Arrow') === -1 && event.code !== 'Backspace') {
                event.preventDefault();
                return;
            }

            if ((event.code.startsWith('Numpad') || event.code.startsWith('Digit')) && isNaN(Number(event.key))) {
                event.preventDefault();
                return;
            }
            
            if (event.code === 'KeyE') {
                event.preventDefault();
                return;
            }
            if (event.key === '-' && this.minNumber > -1) {
                event.preventDefault();
                return;
            }
            if (Number(this.value + event.key) > this.maxNumber) {
                this.setValue(this.maxNumber.toString());
                event.preventDefault();
            }
        }
    }

    filterAutocompleteOptions() {
        if (!this.autocompleteOptions?.length) return;
        if (!this.value || this.value === '') {
            this.filteredAutocompleteOptions$.next(this.autocompleteOptions);
        } else {
            this.filteredAutocompleteOptions$.next(this.autocompleteOptions.filter(x => this.value && x.value.includes(this.value.toLowerCase().trim())));
        }
        this.filteredAutocompleteOptions$.next(this.filteredAutocompleteOptions$.value.sort((a, b) => b.priority - a.priority).slice(0, 5));
    }

    blur() {
        this.focused$.next(false);
        this.sanitizeValue(this.value);
        this.onTouched();
        this.inputBlur.next(this.value);
    }

    focus() {
        this.focused$.next(true);
        if (this.inputElementRef) this.inputElementRef.nativeElement.focus();
        this.filterAutocompleteOptions();
        if (this.autocompleteOptions?.length) this.toggleDropDown(true);
        this.inputFocused.next();
    }

    toggleDropDown(show: boolean) {
        if (!show) {
            if (this.inputElementRef && document.activeElement !== this.inputElementRef.nativeElement) {
                this.showDropDown$.next(show);
            }
        } else this.showDropDown$.next(show);
    }

    selectAutocomplete(option: AutocompleteOption) {
        this.setValue(option.value);
        this.toggleDropDown(false);
    }

    setFileValue(event: Event) {
        const file = (event.target as HTMLInputElement)?.files?.[0];
        this.sanitizeValue(file?.name.trim());
        this.filterAutocompleteOptions();
        if (this.onChange) this.onChange(this.value);
        this.fileValueChanged.emit(file);
    }

    onCountryChange(country: SelectedCountryData): void {
        this.countryCode = country?.iso2 ?? 'US';
        this.sanitizeValue(this.inputElementRef?.nativeElement.value);
    }

    private sanitizeValue(value: string): void {
        this.value = value?.trim ? value?.trim() : value;
        this.cdr.markForCheck();

        this.displayValueSubject.next(cloneDeep(this.value));
        if (this.type === 'phone') setTimeout(() => this.sanitizePhoneNumber());
    }

    private sanitizePhoneNumber(): void {
        if (!this.value) return;
        const parsed = this.tryParsePhoneNumber(this.value, (this.countryCode?.toUpperCase() as CountryCode) ?? 'US');
        if (parsed.isValid()) {
            this.value = parsed.formatInternational().replace(/[^0-9+]/g, '');
            this.cdr.markForCheck();

            this.displayValueSubject.next(cloneDeep(this.value));
            setTimeout(() => this.inputElementRef?.nativeElement.dispatchEvent(new KeyboardEvent('keyup', { 'bubbles': true })));
        }
        if (this.onChange) this.onChange(this.value);
        this.valueChanged.emit(this.value);
    }

    private tryParsePhoneNumber(phone: string, defaultCountry: CountryCode): { isValid(): boolean, formatInternational(): string } {
        try {
            return parsePhoneNumber(phone, defaultCountry);
        } catch {
            return { isValid: () => false, formatInternational: () => '' };
        }
    }

    // Form control interface fns
    onChange = (x: string) => {};

    onTouched = () => {};

    async writeValue(key: string) {
        this.sanitizeValue(key);
    }

    registerOnChange(fn: (key: string) => void) { this.onChange = fn; }

    registerOnTouched(fn: () => void) { this.onTouched = fn; }
    // END Form control
}
