<div class="wrapper" [ngClass]="{'light': lightTheme }">
    <ng-container *ngIf="(tableService.loading$ | async) === false">

        <h1 *ngIf="options?.isTitleVisible" class="title">{{ options?.title }}</h1>

        <ng-container *ngFor="let a of options?.actions">
            <div *ngIf="hasPermission(a)" class="action-buttons">
                <button (click)="a.clickAction?.()" [disabled]="a.disabled?.()" class="action-button">
                    <div *ngIf="a.icon" class="icon-wrapper">
                        <app-icon [faIcon]="a.icon" class="icon"></app-icon>
                    </div>
                    <span>{{ actionTypeToTranslationKey[a.type] ?? '' | translate:{type: a.name} }}</span>
                </button>
            </div>
        </ng-container>

        <ng-container *ngIf="options?.search?.show">
            <app-input
                type="text"
                class="search-input"
                [prependIcon]="options.search.icon"
                [placeholder]="options.search.placeholder"
                (valueChanged)="options.search.searchAction?.(options.search.fields, $event)"
            ></app-input>
        </ng-container>

        <div *ngIf="options?.isPaginationInfoVisible" class="pagination-info">
            {{ 'table.header.pagination-info' | translate:(tableService.paginationInfo$ | async) }}
        </div>

        <!-- Report Actions -->
        <div *ngIf="getSelectedKeyCount() === 0 && options?.reportActions?.length" class="report-actions h100p">
            <ng-container *ngFor="let a of options?.reportActions">
                <div *ngIf="hasPermission(a)" class="report-action-buttons">
                    <button
                        class="report-action-button flexRow jcC aiC p0"
                        (click)="exportReportOutput.emit()"
                        [disabled]="a.disabled?.()"
                        [title]="reportActionTypeToTranslationKey[a.type] ?? '' | translate:{type: a.name} "
                    >
                        <div *ngIf="a.icon" class="icon-wrapper">
                            <app-icon [faIcon]="a.icon" class="icon"></app-icon>
                        </div>
                        <span *ngIf="a.isShowLabel">{{ reportActionTypeToTranslationKey[a.type] ?? '' | translate:{type: a.name} }}</span>
                    </button>
                </div>
            </ng-container>
        </div>
        
        <!-- Bulk Actions -->
        <div class="bulk-actions" *ngIf="getSelectedKeyCount() > 0 && getActionEditOptions().length > 0">
            <app-button-group
                [hasPrimaryAction]="false"
                [buttonText]="getActionButtonText()"
                [class]="['pill']"
                [showOptions]="true"
                [options]="getActionEditOptions()"
                openDirection="bottom-left"
            ></app-button-group>
        </div>


        <button *ngIf="options?.import" 
            [matTooltip]="'table.header.import' | translate:{type: options.import.name}"
            class="import-button"
            (click)="options.import.action()"
        >
            <app-icon [faIcon]="'fas fa-file-import'" class="icon"></app-icon>
        </button>

    </ng-container>
</div>
