import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { DateRange } from 'weavix-shared/models/dvr.model';
import { CATEGORY_PARENT_MAP, filterCategoryToIcon, filterCategoryToTranslationKey, FilterResultType, TableFilterCategory, TableFilterCategoryResults, TableFilterCategoryToIdKey, TableFilterResult, TableHeaderOptions,
    TableHeaderPaginationInfo } from 'weavix-shared/models/table.model';
import { TranslationService } from 'weavix-shared/services/translation.service';


@Injectable({ providedIn: 'root' })
export class TableService {
    public defaultFilters: TableFilterCategoryResults = {};
    public filterCategoryToIdKeyMap: TableFilterCategoryToIdKey = {};

    private loadingSource$ = new BehaviorSubject<boolean>(false);
    private titleSource$ = new Subject<string>();
    private plusIconClickSource$ = new Subject<void>();
    private optionsSource$ = new Subject<TableHeaderOptions>();
    private paginationInfoSource$ = new BehaviorSubject<TableHeaderPaginationInfo>({});

    public searchFilter: {columnNames?: (string | ((row) => any))[], value?: string} = {};
    public selectedFilters: {[key in TableFilterCategory]?: {[key: string]: TableFilterResult}} = {};
    public selectedDateFilters: {[key in TableFilterCategory]?: DateRange} = {};
    public filterSidebarMap: {[key: string]: TableFilterResult} = {};
    filterUpdate$: Subject<{ resetFilters: boolean }> = new Subject();

    constructor(
        private translationService: TranslationService,
    ) { }

    get noFiltersApplied(): boolean {
        return !Object.values(this.selectedDateFilters)?.length &&
            Object.values(this.selectedFilters).every(f => !Object.keys(f).length) &&
            !this.searchFilter?.columnNames &&
            !this.searchFilter?.value;
    }

    get loading$(): Observable<boolean> {
        return this.loadingSource$.asObservable();
    }

    get title$(): Observable<string> {
        return this.titleSource$.asObservable();
    }

    get plusIconClick$(): Observable<void> {
        return this.plusIconClickSource$.asObservable();
    }

    get options$(): Observable<TableHeaderOptions> {
        return this.optionsSource$.asObservable();
    }

    get paginationInfo$(): Observable<TableHeaderPaginationInfo> {
        return this.paginationInfoSource$.asObservable();
    }

    setLoading(loading: boolean): void {
        this.loadingSource$.next(loading);
    }

    setTitle(title: string): void {
        this.titleSource$.next(title);
    }

    onPlusIconClick(): void {
        this.plusIconClickSource$.next();
    }

    setOptions(options: TableHeaderOptions): void {
        this.optionsSource$.next(options);
    }

    setPaginationInfo(paginationInfo: TableHeaderPaginationInfo): void {
        this.paginationInfoSource$.next(paginationInfo);
    }

    resetSelectedFilters(): void {
        this.selectedFilters = _.cloneDeep(this.defaultFilters);
    }

    initFilters(defaultFilters?: TableFilterCategoryResults, filterKeyMap?: TableFilterCategoryToIdKey): void {
        this.defaultFilters = defaultFilters ?? {};
        this.filterCategoryToIdKeyMap = filterKeyMap ?? {};
        this.selectedFilters = _.cloneDeep(this.defaultFilters);
        this.clearFilters();
        this.searchFilter = {};
    }

    clearFilters(): void {
        this.filterSidebarMap = {};
    }

    private setSelectedFilters(category: TableFilterCategory) {
        if (CATEGORY_PARENT_MAP[category]) {

            const parentCategory = CATEGORY_PARENT_MAP[category];

            if (this.filterSidebarMap[parentCategory].children?.[category].selected) {
                this.selectedFilters[category][this.filterSidebarMap[parentCategory].children?.[category].key] = this.filterSidebarMap[parentCategory].children?.[category];
            } else if (this.selectedFilters[category][this.filterSidebarMap[parentCategory].children?.[category].key]) {
                delete this.selectedFilters[category][this.filterSidebarMap[parentCategory].children?.[category].key];
            }
        } else {
            Object.values(this.filterSidebarMap[category].children).forEach(child => {
                if (child.selected) this.selectedFilters[category][child.key] = child;
                else if (this.selectedFilters[category][child.key]) delete this.selectedFilters[category][child.key];
            });
        }

        this.filterUpdate$.next({ resetFilters: false });
    }

    convertItemToFilterResult = (
        i: any,
        c: TableFilterCategory,
        type: FilterResultType,
        appendixCountFn?: (key: string | null, category: TableFilterCategory) => string,
        appendixIcon?: string,
        selected = false,
    ): TableFilterResult => ({
        name: i?.name ?? (filterCategoryToTranslationKey?.[c] ? this.translationService.getImmediate(filterCategoryToTranslationKey[c]) : c),
        icon: i ? null : filterCategoryToIcon?.[c],
        key: i?.id ?? c,
        category: c,
        type,
        data: i,
        hidden: false,
        selected,
        setSelected: () => this.setSelectedFilters(c),
        appendixText: () => appendixCountFn(i?.id, c),
        appendixIcon: { faIcon: appendixIcon ?? 'fas fa-user-friends' },
    });

    populateSidebarFilter(
        entity: any,
        category: TableFilterCategory,
        type: FilterResultType,
        appendixCountFn?: (key: string | null, category: TableFilterCategory) => string,
        appendixIcon?: string,
        multiselect = false,
        skipSortChildren = false,
        selected = false,
    ): void {
        const parentCategory = CATEGORY_PARENT_MAP[category] ?? category;

        if (!this.filterSidebarMap?.[parentCategory]) {
            this.filterSidebarMap[parentCategory] = {
                name: filterCategoryToTranslationKey?.[parentCategory] ? this.translationService.getImmediate(filterCategoryToTranslationKey[parentCategory]) : parentCategory,
                icon: filterCategoryToIcon?.[parentCategory],
                key: parentCategory,
                category: parentCategory,
                type,
                hidden: false,
                children: null,
                multiselect,
                skipSortChildren,
                selected,
            };

            this.filterSidebarMap[parentCategory] = Object.assign({}, this.filterSidebarMap[parentCategory], {
                children: {
                    [entity?.id ?? category]: this.convertItemToFilterResult(entity, category, type, appendixCountFn, appendixIcon, selected),
                },
            });
        } else if (this.filterSidebarMap[parentCategory]?.children && !this.filterSidebarMap[parentCategory].children[entity?.id]) {
            this.filterSidebarMap[parentCategory].children[entity?.id ?? category] = this.convertItemToFilterResult(entity, category, type, appendixCountFn, appendixIcon, selected);
        }
    }

    /**
     * This method determines if the given row passes the given filters.
     * 
     * @param row The row being filtered.
     * @param importedKeyMap The list of filters that apply to the table being filtered mapped to their database names.
     * @param collection The list of filters that are Yes/No Filters.
     * @returns Boolean value indicating whether the row passes the filters.
     */
    checkAndApplyFiltersToRow(row: any, importedKeyMap?: TableFilterCategoryToIdKey, collection?: {[key: string]: Map<string, any>}): boolean {
        const keyMap = importedKeyMap;
        if (
            this.noFiltersApplied || // no filter
            (
                Object.keys(this.selectedDateFilters).every((cat: TableFilterCategory) => { // all date filters available
                    const categoryToIdKeyMap = keyMap[cat];
                    const recordDate = _.get(row, categoryToIdKeyMap.key);
                    const dateRangeFilter = this.selectedDateFilters[cat];
                    return dateRangeFilter ? dateRangeFilter.from <= recordDate && (new Date(dateRangeFilter.to).setDate(dateRangeFilter.to.getDate() + 1)) > recordDate : true;
                })
                &&
                Object.keys(this.selectedFilters).every((cat: TableFilterCategory) => { // all filter categories available
                    const categoryToIdKeyMap = keyMap[cat];
                    return this.filterCategoryApplied(
                        cat,
                        [].concat(_.get(row, categoryToIdKeyMap.key)),
                        categoryToIdKeyMap.collection ? collection : null,
                    );
                })
                && this.filterSearchApplied(row) // search filter
            )
        ) {
            return true;
        }
    }

    private filterCategoryApplied(cat: TableFilterCategory, ids: (string | {id: string, name: string})[] = [], collection?: { [key: string]: Map<string, any> }): boolean {
        const idStrings = ids.filter(obj => obj !== undefined).map(obj => typeof obj === 'string' ? obj : obj.id);
        
        return !Object.keys(this.selectedFilters[cat]).length 
            || (collection && idStrings.some(x => collection[cat]?.get(x))) 
            || idStrings.some(x => this.selectedFilters[cat][x]);
    }
    

    private filterSearchApplied(row: any): boolean {
        return !this.searchFilter?.columnNames || !this.searchFilter.value
        || this.searchFilter.value?.toLowerCase().trim().split(' ')
            .every(v => this.searchFilter.columnNames.some(c => {
                if (typeof c === 'function')
                    return c.call(this, row)?.toLowerCase()?.includes(v);
                else
                    return _.get(row, c)?.toLowerCase()?.includes(v);
            }));
    }
}
