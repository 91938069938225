import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, forwardRef, Injector, Input, OnChanges, Output, SimpleChanges, TrackByFunction, ViewChild } from '@angular/core';
import { ControlValueAccessor, NgControl, NG_VALUE_ACCESSOR, FormsModule } from '@angular/forms';
import { TranslationService } from 'weavix-shared/services/translation.service';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { TooltipOptions } from 'weavix-shared/models/tooltip.model';
import { AutoUnsubscribe, Utils } from 'weavix-shared/utils/utils';
import { DropdownItem, DropdownItemGroup } from './dropdown.model';
import { environment } from 'environments/environment';
import { ThemeService } from 'weavix-shared/services/themeService';
import { CommonModule } from '@angular/common';
import { TooltipComponent } from 'components/tooltip/tooltip.component';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacySelect as MatSelect, MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { IconComponent } from 'components/icon/icon.component';
import { TranslateModule } from '@ngx-translate/core';
import { LoadingComponent } from 'components/loading/loading.component';

/**
 * Note: [(ngModel)] is DropdownItem.key, not the item itself.
 */
@AutoUnsubscribe()
@Component({
    selector: 'app-dropdown',
    templateUrl: './dropdown.component.html',
    styleUrls: ['./dropdown.component.scss', './dropdown-teams.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DropdownComponent),
            multi: true,
        },
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        TranslateModule,

        TooltipComponent,
        IconComponent,
        LoadingComponent,
    ],
})
export class DropdownComponent implements ControlValueAccessor, OnChanges {
    @Input() selectId = 'dropdown';
    @Input() doTranslate = true;
    @Input() dropdownLabel: string;
    @Input() customWidth: number;
    @Input() dropdownInput: DropdownItem[] = [];
    @Input() dropdownGroupInput: DropdownItemGroup[] = [];
    @Input() setDefault: boolean = true;
    @Input() required: boolean = false;
    @Input() readonly: boolean = false;
    @Input() isClickable: boolean = false;
    @Input() hasAdd: DropdownItem = null;
    @Input() showAdd: boolean = true;
    @Input() emptyOption: string = null;
    @Input() skipSort: boolean = false;
    @Input() showHasMoreIndicator: boolean = false;
    @Input() tooltip: TooltipOptions;
    @Input() showErrors: boolean = true;
    @Input() errorClass: string;
    @Input() placeholder: string;
    @Input() white: boolean = false; // WHITE background
    @Input() dkGray: boolean = false;
    @Input() minChars: number = 0;
    @Input() lightThemeOverride: boolean;
    @Input() showLoading: boolean = true;
    @Input() sortBySublabel: boolean = false;
    @Output() dropdownOutput = new EventEmitter<DropdownItem>();
    @Output() filterChange = new EventEmitter<string>();
    @Input() floatedLabel: string;
    /** Show red asterisk without displaying required errors (useful for custom errors) */
    @Input() showAsterisk: boolean = false;
    /** Show error messages only when dropdown has been touched */
    @Input() requireTouchedForError: boolean = true;
    @Input() errorLabel: string;
    @Input() showLabel: boolean = true;
    @Input() searchDisabled: boolean = false;
    @Input() customMarginTop: number;
    @Input() customMarginBottom: number;
    /**
     * Classes to apply to the dropdown overlay container.
     */
    @Input() panelClass: string | string[];
    @ViewChild('matSelect') matSelect?: MatSelect;
    @ViewChild('filterInput') filterInput?: ElementRef<HTMLInputElement>;

    teamsApp = environment.teamsApp;

    readonly lightTheme: boolean;
    isLoading: boolean = false;
    selectedItem: DropdownItem;
    filteredInput: DropdownItemGroup[] = [];
    filterValue: string = '';
    dropdownItems: DropdownItem[] = [];
    inputFilterChanged: Subject<string> = new Subject<string>();

    formControl?: NgControl;

    private sortedInput: DropdownItemGroup[] = [];
    private selectedValue: string;

    get placeHolderText() {
        if (this.filterChange.observers.length > 0) {
            return this.translationService.getImmediate('shared.generics.start-typing-person');
        }
        return '';
    }

    get isInputFilterValueValid(): boolean { return this.filterInput?.nativeElement.value?.trim().length >= this.minChars; }

    trackDropdownItemFn: TrackByFunction<DropdownItem> = (_, item) => item.key;

    constructor(
        private injector: Injector,
        private translationService: TranslationService,
        private cdr: ChangeDetectorRef,
    ) {
        this.lightTheme = ThemeService.getLightTheme();
        this.inputFilterChanged
            .pipe(debounceTime(300))
            .pipe(distinctUntilChanged((prev, curr) => {
                const isSame = prev === curr;
                if (isSame) {
                    this.isLoading = false;
                    this.cdr.markForCheck();
                }
                return isSame;
            }))
            .pipe(filter((value) => value?.trim().length >= this.minChars))
            .subscribe(value => {
                if (value !== undefined) {
                    if (this.filterChange.observers.length) {
                        this.filterChange.emit(value);
                    } else {
                        this.filteredInput = this.sortedInput.slice().map(g => {
                            const filteredItems = g.items.slice().filter(v => {
                                const translatedLabel = this.doTranslate ? this.translationService.getImmediate(v.label) : v.label;
                                return !v.placeholder && translatedLabel?.toLowerCase().indexOf(value.trim().toLowerCase()) >= 0;
                            });

                            return {
                                items: filteredItems,
                                label: g.label,
                            };
                        }).filter(x => x.items.length);
                    }
                }
                this.isLoading = false;
                this.cdr.markForCheck();
            });
    }

    ngOnChanges(changes: SimpleChanges) {
        try { this.formControl = this.injector.get(NgControl, null); } catch (e) {
            console.error('DropdownComponent could not inject NgControl.', e);
        }

        if (changes['dropdownInput'] || changes['dropdownGroupInput']) {
            this.init(!!changes['dropdownGroupInput']);
            this.isLoading = false;
            this.cdr.markForCheck();
        }

        // change placeholder text if it was updated
        if (changes['placeholder'] && this.placeholder != null) {
            const placeHolderIndex = this.dropdownGroupInput[0].items.findIndex((item) => item.placeholder);
            if (placeHolderIndex >= 0) {
                this.dropdownGroupInput[0].items[placeHolderIndex].label = this.translationService.getImmediate(this.placeholder);
            }
        }
        if (changes['panelClass']) {
            this.panelClass = Array.isArray(this.panelClass) ? this.panelClass : [this.panelClass];
            if (this.teamsApp) this.panelClass.push('teams');
        }
    }

    //#region Setup
    private init(grouped: boolean) {
        const itemSelector: ((item: DropdownItem) => string) = this.doTranslate
            ? ((item) => this.translationService.getImmediate((this.sortBySublabel ? item.subLabel : item.label) ?? item.label))
            : (item => (this.sortBySublabel ? item.subLabel : item.label) ?? item.label);
        const groupSelector: ((group: DropdownItemGroup) => string) = this.doTranslate
            ? ((group) => this.translationService.getImmediate(group.label))
            : (group => group.label);

        if (!grouped) this.dropdownGroupInput = [{
                items: (this.dropdownInput ?? []).slice(),
            }];
        if (this.dropdownGroupInput.length > 1 && !this.skipSort) this.dropdownGroupInput.sort(Utils.alphabeticalSorter(groupSelector));
        if (this.placeholder != null) {
            this.dropdownGroupInput[0].items.unshift({ key: null, label: this.doTranslate ? this.translationService.getImmediate(this.placeholder) : this.placeholder, placeholder: true, disabled: true });
        }
        if (this.emptyOption != null) {
            this.dropdownGroupInput[0].items.unshift({ key: null, label: this.doTranslate ? this.translationService.getImmediate(this.emptyOption) : this.emptyOption });
        }
        this.dropdownItems = [];
        this.dropdownGroupInput.forEach(group => {
            this.dropdownItems = this.dropdownItems.concat(group.items);
            if (!this.skipSort) group.items.sort(Utils.alphabeticalSorter(itemSelector));
        });
        this.sortedInput = this.dropdownGroupInput;

        if (this.sortedInput[0].items?.length) {
            this.setDefaultFilteredInput();
            this.setDefaultSelection();
        }
    }

    // set filteredInput collection to contain the selected item only if the list is lazy loaded
    // otherwise, set it to the sortedInput collection - this is to avoid loading ALL items in the dropdown unnecessarily
    private setDefaultFilteredInput(): void {
        this.filteredInput = this.minChars ? this.sortedInput.slice().map(g => {
            return {
                items: g.items.slice().filter(x => x.key === this.selectedValue),
                label: g.label,
            };
        }) : this.sortedInput;
    }

    private setDefaultSelection(): void {
        const selectedItem = this.dropdownItems.find(item => item.key === this.selectedValue || !item.key && !this.selectedValue);
        if (selectedItem) {
            this.selectedItem = selectedItem;
        } else if (this.setDefault) {
            this.selectedItem = this.sortedInput[0].items[0];
        }
        if (this.selectedItem && this.setDefault) this.onChange(this.selectedItem.key);
    }

    //#endregion Setup

    onSelectionChange(): void {
        if (this.hasAdd && this.selectedItem.key === this.hasAdd.key) {
            this.dropdownOutput.emit({ key: this.hasAdd.key, label: this.filterValue });
        } else {
            if (this.selectedItem) {
                this.selectedValue = this.selectedItem.key;
            }
            this.onChange(this.selectedItem?.key);
            this.dropdownOutput.emit(this.selectedItem);
        }
        this.resetFilteredList();
    }

    filterList(value: string) {
        if (!this.isInputFilterValueValid) return;
        this.isLoading = value !== undefined;
        this.inputFilterChanged.next(value);
    }

    customDropdownOpen(): void {
        this.matSelect?.open();
    }

    preventSpaceBar(event: KeyboardEvent) {
        if (event.code === 'Space') event.stopPropagation();
    }

    onFilterBlur(event: any): void {
        if (event.relatedTarget?.classList?.contains('mat-option')) return; // blur event was triggered from option selection
        if (this.filterValue && this.filteredInput.length === 1 && this.filteredInput[0].items.length === 1) {
            this.selectedItem = this.filteredInput[0].items[0];
            this.selectedValue = this.selectedItem.key;
            this.onChange(this.selectedItem?.key);
            this.dropdownOutput.emit(this.selectedItem);
        }
        this.resetFilteredList();
    }

    resetFilteredList(): void {
        setTimeout(() => {
            this.filterValue = '';
            this.inputFilterChanged.next('');
            this.setDefaultFilteredInput();
            this.cdr.markForCheck();
        }, 200);
    }

    async focusFilter(open: boolean) {
        if (open) {
            this.filterInput?.nativeElement.focus();
        } else {
            this.filterValue = '';
            this.filterList(undefined);
        }
    }

    // Form control interface fns
    onChange = (x: string) => {};

    onTouched = () => {};

    async writeValue(key: string) {
        this.selectedItem = this.dropdownItems.find(item => item.key === key);
        this.selectedValue = key;
        this.cdr.markForCheck();
    }

    registerOnChange(fn: (key: string) => void) { this.onChange = fn; }

    registerOnTouched(fn: () => void) { this.onTouched = fn; }

    // END Form control
}
