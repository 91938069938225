import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { DefaultItemType, defaultItemTypeSvgs } from '../models/default-item-type.model';
import { ItemUpdate } from '@weavix/models/src/badges/event';
import { SelectionOptions } from '../models/folder.model';
import { Resource } from '../models/folderized-data.model';
import { AnyItem, Item, ItemBaseType, ItemLocationUpdate, ItemType } from '../models/item.model';
import { Topic } from '@weavix/models/src/topic/topic';
import { WorkFormSubmission } from '../models/work-forms.model';
import { Utils } from '../utils/utils';
import { AccountService } from './account.service';
import { AlertService } from './alert.service';
import { CacheContext, HttpService } from './http.service';
import { PubSubService } from './pub-sub.service';
import { TranslationService } from './translation.service';

// A color from the /colors endpoint
const defaultIconColor: string = '#17202a';

@Injectable({
    providedIn: 'root',
})
export class ItemService {

    constructor(
        private httpService: HttpService,
        private pubSubService: PubSubService,
        private accountService: AccountService,
        private alertsService: AlertService,
        private translationService: TranslationService,
    ) { }

    static readonly ACTIVE_DYNAMIC_ITEM_TIME_MINUTES = 30;
    static itemTypesCacheCollection = 'ItemTypes';
    static itemsCacheCollection = 'Items';
    private static readonly itemTypeCacheContext: CacheContext = { collection: ItemService.itemTypesCacheCollection, maxAge: 1800000 };
    private static readonly itemCacheContext: CacheContext = { collection: ItemService.itemsCacheCollection, maxAge: 1800000 };

    static baseItemsUrl = '/core/items';
    static baseItemTypesUrl = '/core/item-types';

    private colors$: BehaviorSubject<string[]>;
    private icons$: BehaviorSubject<string[]>;

    itemTypeMap: {[key: string]: ItemType} = {};
    static itemsUrl = (id?: string) => id ? `${ItemService.baseItemsUrl}/${id}` : ItemService.baseItemsUrl;
    static itemTypesUrl = (id?: string) => id ? `${ItemService.baseItemTypesUrl}/${id}` : ItemService.baseItemTypesUrl;

    public static clearItemsTypeCache = () => HttpService.clearCache(ItemService.itemTypeCacheContext.collection);
    public static clearItemCache = () => HttpService.clearCache(ItemService.itemCacheContext.collection);

    static isDefaultItemType(item: Item): boolean {
        return Object.values(DefaultItemType).some(type => type === item.typeId);
    }

    isConfinedSpaceItem(item: Item): boolean {
        const itemType: ItemType = this.itemTypeMap[item.typeId];
        return itemType?.baseType === ItemBaseType.ConfinedSpace;
    }

    getItem(component: any, id: string) {
        return this.httpService.get<AnyItem>(component, ItemService.itemsUrl(id), null, ItemService.itemCacheContext);
    }

    getItems(component: any, facilityId?: string, mapItem?: boolean, baseType?: ItemBaseType, tags?: string[], getCached: boolean = true) {
        return this.httpService.get<AnyItem[]>(component, ItemService.itemsUrl(), { facilityId, tags, mapItem, baseType }, getCached ? ItemService.itemCacheContext : null);
    }

    async getItemResources(component, selectionOptions?: SelectionOptions, facilityId?: string): Promise<Resource[]> {
        try {
            let resources: Resource[] = [];
            resources = (await this.getItems(component, facilityId)).map(i => this.makeResource(i as AnyItem, selectionOptions ? selectionOptions : { ids: true }));
            return Utils.sortAlphabetical(resources, (item) => item.displayText);
        } catch (e) {
            this.alertsService.sendError(e, 'ERRORS.ITEMS.GET');
            return [];
        }
    }

    makeResource(i: AnyItem, selectionOptions: SelectionOptions): Resource {
        return {
            displayText: i.name,
            key: i.id,
            data: i,
            parentId: i.folderId || i.facilityId || null,
            isSelectable: selectionOptions.ids ? true : false,
        };
    }

    getAccountOnlyItems(component: any, tags?: string[]) {
        return this.httpService.get<AnyItem[]>(component, ItemService.itemsUrl(), { facilityId: 'root', tags }, ItemService.itemCacheContext);
    }

    createItem(component: any, item: AnyItem) {
        ItemService.clearItemsTypeCache();
        return this.httpService.post<AnyItem>(component, ItemService.itemsUrl(), item, ItemService.itemCacheContext);
    }

    updateItem(component: any, id: string, item: AnyItem) {
        return this.httpService.put<AnyItem>(component, ItemService.itemsUrl(id), item, ItemService.itemCacheContext);
    }

    deleteItem(component: any, id: string) {
        ItemService.clearItemsTypeCache();
        return this.httpService.delete<AnyItem>(component, ItemService.itemsUrl(id), null, ItemService.itemCacheContext);
    }

    getFormSubmissions(component: any, id: string) {
        return this.httpService.get<WorkFormSubmission[]>(component, `${ItemService.itemsUrl(id)}/submissions`, null, ItemService.itemCacheContext);
    }

    async getAllTypes(component: any, tags?: string[], getCached: boolean = true) {
        let types = await(this.httpService.get<ItemType[]>(component, ItemService.itemTypesUrl(), { tags }, getCached ? ItemService.itemTypeCacheContext : null));
        types = types.map(t => (this.checkToUpdateDefaultTypes(t)));

        return types;
    }

    async buildItemTypeMap(component) {
        const lists = await this.getAllTypes(component);
        this.itemTypeMap = lists.reduce((a, b) => { a[b.id] = b; return a; }, {});
    }

    async getType(component: any, id: string) {
        const type = await(this.httpService.get<ItemType>(component, ItemService.itemTypesUrl(id), null, ItemService.itemTypeCacheContext));
        return this.checkToUpdateDefaultTypes(type);
    }

    async createType(component: any, type: ItemType) {
        const result = await this.httpService.post<ItemType>(component, ItemService.itemTypesUrl(), type, ItemService.itemTypeCacheContext);
        this.itemTypeMap[result.id] = result;
        return result;
    }

    async updateType(component: any, id: string, type: ItemType) {
        const result = await this.httpService.put<ItemType>(component, ItemService.itemTypesUrl(id), type, ItemService.itemTypeCacheContext);
        this.itemTypeMap[result.id] = result;
        return result;
    }

    async deleteType(component: any, id: string) {
        const result = await this.httpService.delete<ItemType>(component, ItemService.itemTypesUrl(id), null, ItemService.itemTypeCacheContext);
        delete this.itemTypeMap[result.id];
        return result;
    }

    async subscribeUpdates(c: any) {
        return (await this.pubSubService.subscribe<ItemUpdate>(c, Topic.AccountItems, [this.accountService.getAccountId()]));
    }

    async subscribeLocationUpdates(component: any) {
        return (await this.pubSubService.subscribe<ItemUpdate>(component, Topic.AccountItemLocationUpdated, [this.accountService.getAccountId()]));
    }

    async subscribeFacilityLocationUpdates(component: any, facilityId: string) {
        return (
            await this.pubSubService.subscribe<ItemLocationUpdate>(
                component, Topic.AccountFacilityItemLocationUpdated, [this.accountService.getAccountId(), facilityId],
            )
        );
    }

    async icons(component: any) {
        if (!this.icons$) {
            this.icons$ = new BehaviorSubject(await this.httpService.get<string[]>(component, `${ItemService.itemTypesUrl()}/icons`));
        }
        return await this.icons$.pipe(take(1)).toPromise();
    }

    async colors(component: any) {
        if (!this.colors$) {
            this.colors$ = new BehaviorSubject(await this.httpService.get<string[]>(component, `${ItemService.itemTypesUrl()}/colors`, undefined, null, null));
        }
        return await this.colors$.pipe(take(1)).toPromise();
    }

    defaultIconColor() {
        return defaultIconColor;
    }

    private checkToUpdateDefaultTypes(type: ItemType): ItemType {
        if (!type.isDefault) return type;

        return {
            ...type,
            name: this.translationService.getImmediate(`items.types.defaults.${type.id}`),
        };
    }

    getDefaultItemTypeSvgPath(type: DefaultItemType): string {
        const color = this.itemTypeMap[type].icon.color;
        return defaultItemTypeSvgs[type](color);
    }
}
