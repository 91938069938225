import { Point, Vertices } from './location.model';
import { FacilitySettings, WaltSettings } from '@weavix/models/src/facility/facility';

export const BEACON_NUMBER_PREFIX = 'weavix-';
export const BEACON_NUMBER_PREFIX_DISPLAY = 'BEACON ';
export const DEFAULT_BEACON_ENTER_DISTANCE = 5;
export const DEFAULT_BEACON_ENTER_TIME = 15000;
export const DEFAULT_BEACON_EXIT_DISTANCE = 5;
export const DEFAULT_BEACON_EXIT_TIME = 15000;

export type MapDeviceType = Beacon | Wrangler | WifiRouter;

export interface Beacon {
    id: string;
    facilityId: string;
    structureId?: string;
    location: Point;
    name: string;
    level: number;
    surveyed?: boolean;
    tracking?: boolean;
    event?: boolean;
    battery?: number;
    enterDistance?: number[];
    enterTime?: number;
    exitDistance?: number[];
    exitTime?: number;
    lastSeen?: string;
    snapDistance?: number;
}

export interface TrackingCircle {
    latitude: number;
    longitude: number;
    radius: number;
}

export enum WifiRouterUsage {
    Static = 'static',
    Dynamic = 'dynamic',
    Ignored = 'ignored',
}

export interface WifiRouter {
    id: string;
    ssid: string;
    location: Point;
    level?: number;
    structureId?: string;
    lastSeen: string;
    usage: WifiRouterUsage;
    name?: string;
    height?: number;
    power?: number;
    snapDistance?: number;
}

export interface UpdateWifiRouter {
    id: string;
    usage?: string;
    location?: number[];
    level?: number;
    structureId?: string;
    name?: string;
    ssid?: string;
    height?: number;
    power?: number;
    snapDistance?: number;
}

export interface AddWifiRouter {
    id: string;
    facilityId: string;
    ssid: string;
    location: Point;
    usage: WifiRouterUsage;
    lastSeen: string;
}

export interface Wrangler {
    id: string;
    facilityId: string;
    name: string;
    location: Point;
    structureId?: string;
    level?: number;
    /**
     * Indicates whether this Wrangler belongs to a group or not.
     * If true, the Wrangler is a group and the name is the group name.
     * If false, the Wrangler is an individual.
     * If not set, the Wrangler is an individual.
     * This value will not be saved in the database, but rather user for UI purposes.
     * @type {boolean}
     * @optional
     */
    isGroup?: boolean;
}

export interface UpdateWrangler {
    id: {
        facilityId: string;
        wranglerId: string;
    };
    name: string;
    location: Point;
    structureId?: string;
    level?: number;
}

export interface AddFacilityPersonRequest {
    personId: string;
    facilityId: string;
    group: string;
}

export interface Facility {
    id?: string;
    accountId?: string;
    name: string;
    address: string;
    location: Point;
    bounds: Vertices;
    zoom: number;
    image?: FacilityImage;
    tags?: string[];
    folderId?: string;
    timezone?: string;
    settings?: FacilitySettings;
    beacons?: Beacon[];
    wranglers?: Wrangler[];
    trackingCircles?: TrackingCircle[];
    waltSettings?: WaltSettings;
}

export interface FacilityImage {
    uri?: string;
    placement?: {
        bounds: Vertices;
        rotation: number;
        vertices: Vertices;
    };
}

export type UpdateSettingsRequest = Partial<FacilitySettings>;

export interface Simulator {
    personCount?: number;
    itemCount?: number;
    running?: boolean;
    error?: string;
    enabled?: Date;
    disabled?: Date;
}


export const addBeaconNumberPrefix = (id: string): string => {
    const beaconId = id?.replace(new RegExp(BEACON_NUMBER_PREFIX, 'i'), '');
    return `${BEACON_NUMBER_PREFIX}${beaconId}`;
};

export const removeBeaconNumberPrefix = (beacon: Beacon): Beacon => {
    const beaconId = beacon.id?.replace(new RegExp(BEACON_NUMBER_PREFIX, 'i'), '');
    return Object.assign({}, beacon, { id: beaconId });
};
