import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, forwardRef, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatLegacyChipList as MatChipList, MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { TranslateModule } from '@ngx-translate/core';
import { ChipComponent } from 'components/chip-list/chip/chip.component';
import { LoadingComponent } from 'components/loading/loading.component';
import { PreventBlurDirective } from 'weavix-shared/directives/prevent-blur.directive';
import { FilterFolder, Folder, FolderType } from 'weavix-shared/models/folder.model';
import { PermissionAction } from 'weavix-shared/permissions/permissions.model';
import { FolderService } from 'weavix-shared/services/folder.service';
import { ProfileService } from 'weavix-shared/services/profile.service';
import { TranslationService } from 'weavix-shared/services/translation.service';
import { sleep } from 'weavix-shared/utils/sleep';
import { AutoUnsubscribe, Utils } from 'weavix-shared/utils/utils';

@AutoUnsubscribe()
@Component({
    selector: 'app-folder-tree-select',
    templateUrl: './folder-tree-select.component.html',
    styleUrls: ['./folder-tree-select.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FolderTreeSelectComponent),
            multi: true,
        },
    ],
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        MatChipsModule,
        MatFormFieldModule,
        TranslateModule,

        ChipComponent,
        LoadingComponent,
        PreventBlurDirective,
    ],
})
export class FolderTreeSelectComponent implements ControlValueAccessor, OnInit, OnChanges {
    @Input() selectedIds: string[] = [];
    @Input() required: boolean = false;
    @Input() floatLabel: boolean = true;
    @Input() rootAll: boolean = false;
    @Input() selectionMax: number | null = null;
    @Input() autofocus: boolean = false;
    @Input() readonly: boolean = false;
    @Input() folderType: FolderType;
    @Input() permission: PermissionAction;
    @Input() facilityId: string = '';
    @Input() label: string = 'table.folders.folders';
    @Input() translate: boolean = true;

    @Output() selectedFolders: EventEmitter<Folder[]> = new EventEmitter();
    @Output() uiUpdate: EventEmitter<void> = new EventEmitter(); // Needed for table since it does onPush detection

    @ViewChild('filter', { static: true }) filterContainer: ElementRef;
    @ViewChild('chipList', { static: true }) chips: MatChipList;
    @ViewChild('search') searchInput: ElementRef;

    loading: boolean = false;
    allFolders: FilterFolder[] = [];
    showFolders: boolean = false;
    showTree: boolean = true;
    curTree: FilterFolder;
    searchQuery: string = '';
    searchResults: FilterFolder[] = [];

    lastHovered: FilterFolder;
    folderHovered: boolean = false;
    curIndex: number = 0;

    selectedFilterFolders: FilterFolder[] = [];

    touched: boolean = false;
    treePromise: Promise<any>;

    constructor(
        private folderService: FolderService,
        private cdr: ChangeDetectorRef,
        private translationService: TranslationService,
        private profileService: ProfileService,
    ) { }

    async ngOnInit() {
        this.uiUpdate.subscribe(() => {
            this.cdr.markForCheck();
        });
        this.loading = true;
        this.uiUpdate.emit();
        if (!this.treePromise) this.treePromise = this.getTree();
        await this.treePromise;
        this.loading = false;
        if (this.autofocus) {
            await sleep(1);
            this.searchFocused();
        }
        this.uiUpdate.emit();
    }

    async ngOnChanges(changes: SimpleChanges) {
        if (changes.selectedIds && this.selectedIds && this.selectedIds.length > 0
            || changes.facilityId || changes.folderType) {
            this.loading = true;
            this.uiUpdate.emit();
            this.treePromise = this.getTree(true);
            await this.treePromise;
            this.selectedFilterFolders = [];
            this.fillUpSelectedFoldersIds();
            this.loading = false;
            this.uiUpdate.emit();
        }
    }

    @HostListener('window:keyup', ['$event'])
    keyEvent(event: KeyboardEvent) {
        if (this.loading || !this.showFolders) return;
        if (event.key === 'Enter') {
            if (this.lastHovered) {
                if (!this.selectedFilterFolders.map(t => t.id).includes(this.lastHovered.id)) this.add(this.lastHovered, this.curIndex);
            }
        }
        if (event.key === 'ArrowDown') {
            this.hoverVertical(false);
        }
        if (event.key === 'ArrowUp') {
            this.hoverVertical(true);
        }
        if (event.key === 'ArrowLeft') {
            if (this.folderHovered) {
                this.back();
            }
        }
        if (event.key === 'ArrowRight') {
            if (this.lastHovered.children && this.lastHovered.children.length > 0) {
                this.next(this.lastHovered);
            }
        }
    }

    searchUpdated() {
        if (this.searchQuery.length === 0) {
            this.showTree = true;
            this.checkSelected(this.curTree.children);
        } else {
            this.showTree = false;
            this.searchResults = this.allFolders.filter(t => t.name.toLowerCase().includes(this.searchQuery.toLowerCase()))
                .filter(t => this.profileService.hasPermission(this.permission, this.facilityId, t.id));
            this.checkSelected(this.searchResults);
        }

        this.showFolders = true;

        this.autoHoverFirstItem();
        this.uiUpdate.emit();
    }

    add(folder: FilterFolder, index: number) {
        if (!this.selectedFilterFolders.some(f => f === folder)) {
            this.selectedFilterFolders.push(folder);
            folder.selected = true;

            let parent = folder.parent;
            while (parent) {
                if (parent.selected) {
                    parent.selected = false;
                    this.selectedFilterFolders.splice(this.selectedFilterFolders.findIndex(t => t.id === parent.id), 1);
                }
                parent = parent.parent;
            }
            const removeChildren = (f) => {
                (f.children || []).forEach(c => {
                    if (c.selected) {
                        c.selected = false;
                        this.selectedFilterFolders.splice(this.selectedFilterFolders.findIndex(t => t.id === c.id), 1);
                    }
                    removeChildren(c);
                });
            };
            removeChildren(folder);
            this.selectedFilterFolders = this.selectedFilterFolders.filter(f => {
                if (!f.id && folder.id || f.id && !folder.id) {
                    f.selected = false;
                    return false;
                }
                return true;
            });
        }
        if (this.selectionMax !== null && this.selectedFilterFolders.length > this.selectionMax) {
            this.remove(this.selectedFilterFolders[0]);
        }
        this.selectedFolders.emit(this.selectedFilterFolders as Folder[]);
        this.onChange(this.selectedFilterFolders.map(t => t.id));
        this.chips.errorState = this.required && this.selectedFilterFolders.length < 1;
        this.searchQuery = '';
        this.searchUpdated();
        this.uiUpdate.emit();
    }

    didItReachMax() {
        return this.selectionMax !== null && this.selectedFilterFolders.length >= this.selectionMax;
    }

    remove(folder: FilterFolder) {
        this.selectedFilterFolders.splice(this.selectedFilterFolders.findIndex(t => t.id === folder.id), 1);
        folder.selected = false;
        if (!this.selectedFilterFolders.length) {
            const root = this.allFolders.find(f => !f.id) ??
                this.curTree?.children?.[0] ?? this.allFolders[0];
            this.selectedFilterFolders.push(root);
            root.selected = true;
        }
        this.selectedFolders.emit(this.selectedFilterFolders as Folder[]);
        this.onChange(this.selectedFilterFolders.map(t => t.id));
        this.chips.errorState = this.required && this.selectedFilterFolders.length < 1;
        this.uiUpdate.emit();
    }

    async back() {
        if (!this.canGoBack()) return;
        await sleep(1);
        this.loading = true;
        this.uiUpdate.emit();
        this.curTree = this.curTree.parent;
        this.checkSelected(this.curTree.children);
        this.autoHoverFirstItem();
        this.loading = false;
        this.uiUpdate.emit();
    }

    canGoBack() {
        return !!this.curTree.parent;
    }

    async next(folder) {
        await sleep(1);
        this.loading = true;
        this.uiUpdate.emit();
        this.curTree = folder;
        this.showTree = true;
        this.searchQuery = '';
        this.checkSelected(this.curTree.children);
        this.autoHoverFirstItem();
        this.loading = false;
        this.uiUpdate.emit();
    }

    checkSelected(list: FilterFolder[]) {
        list.forEach(f => {
            f.selected = this.selectedFilterFolders.some(sf => sf.id === f.id);
        });
        this.uiUpdate.emit();
    }

    autoHoverFirstItem() {
        this.folderHovered = false;
        this.curIndex = 0;
        if (this.lastHovered) this.lastHovered.hovered = false;
        if (this.showTree) {
            if (this.curTree.children && this.curTree.children.length > 0) {
                this.curTree.children[0].hovered = true;
                this.lastHovered = this.curTree.children[0];
            }
        } else {
            if (this.searchResults.length > 0) {
                this.searchResults[0].hovered = true;
                this.lastHovered = this.searchResults[0];
            }
        }
    }

    searchFocused() {
        if (this.readonly) return;
        if (this.searchInput && this.searchInput.nativeElement) this.searchInput.nativeElement.focus();
        if (!this.showFolders) {
            this.showFolders = true;
            this.autoHoverFirstItem();
        }
    }

    blur() {
        this.showFolders = false;
        this.onTouched();
        this.touched = true;
    }

    clickedOutside() {
        this.showFolders = false;
        this.searchQuery = '';
    }

    hoverVertical(up: boolean) {
        if (up) {
            if (this.showTree) {
                if (this.curIndex === 0 && this.lastHovered && this.curTree.parent && !this.folderHovered) {
                    this.lastHovered.hovered = false;
                    this.curIndex = 0;
                    this.folderHovered = true;
                } else if (this.curIndex > 0 && this.lastHovered) {
                    this.lastHovered.hovered = false;
                    this.curIndex--;
                    this.curTree.children[this.curIndex].hovered = true;
                    this.lastHovered = this.curTree.children[this.curIndex];
                }
            } else {
                if (this.curIndex > 0 && this.lastHovered) {
                    this.lastHovered.hovered = false;
                    this.curIndex--;
                    this.searchResults[this.curIndex].hovered = true;
                    this.lastHovered = this.searchResults[this.curIndex];
                }
            }
        } else {
            if (this.showTree) {
                if (this.folderHovered) {
                    this.autoHoverFirstItem();
                } else if (this.lastHovered && this.curTree.children[this.curIndex + 1]) {
                    this.lastHovered.hovered = false;
                    this.curIndex++;
                    this.curTree.children[this.curIndex].hovered = true;
                    this.lastHovered = this.curTree.children[this.curIndex];
                }
            } else {
                if (this.lastHovered && this.searchResults[this.curIndex + 1]) {
                    this.lastHovered.hovered = false;
                    this.curIndex++;
                    this.searchResults[this.curIndex].hovered = true;
                    this.lastHovered = this.searchResults[this.curIndex];
                }
            }
        }
        if (this.lastHovered) {
            const element = document.getElementById(`folder${this.lastHovered.id}`);
            if (element) element.scrollIntoView({ behavior: 'smooth', inline: 'nearest' });
        }
        this.uiUpdate.emit();
    }

    private fillUpSelectedFoldersIds() {
        if (this.selectionMax != null && this.selectedIds.length > this.selectionMax) {
            this.selectedIds = this.selectedIds.slice(0, this.selectionMax);
        }
        let findFolder: FilterFolder;
        this.selectedIds.filter(id => {
            findFolder = this.allFolders.find(t => t.id === id);
            if (findFolder && !this.selectedFilterFolders.some(f => f === findFolder)) this.selectedFilterFolders.push(findFolder);
        });
    }

    // Form control interface fns
    onChange = (_: string[]) => {};

    onTouched = () => {};

    async writeValue(folders: string[] = []) {
        if (!folders) return;
        this.selectedIds = folders;
        this.selectedFilterFolders = [];
        if (!this.treePromise) this.treePromise = this.getTree();
        await this.treePromise;
        this.fillUpSelectedFoldersIds();
        this.checkSelected(this.curTree.children);
        // Need chip list that is on ngIf to render before setting errorState
        await sleep(1);
        this.chips.errorState = this.required && this.selectedFilterFolders.length < 1;
        this.uiUpdate.emit();
    }

    registerOnChange(fn: (ids: string[]) => void) { this.onChange = fn; }

    registerOnTouched(fn: () => void) { this.onTouched = fn; }
    // END Form control

    private async getTree(force: boolean = false) {
        if (!this.curTree || force) {
            this.curTree = await this.folderService.getTree(this, this.folderType, this.facilityId);
            this.curTree.children = [{
                id: '',
                name: this.translationService.getImmediate(this.rootAll ? 'generics.all' : 'table.no-folder'),
                children: [],
                parent: this.curTree,
            } as any].concat(this.curTree.children);
            this.filterPermissions(this.curTree, this.curTree);
            Utils.sortAlphabetical(this.curTree.children, x => x.name);
            this.allFolders = [];
            const addFolder = (folder) => {
                this.allFolders.push(folder);
                (folder.children || []).forEach(addFolder);
            };
            this.curTree.children.forEach(addFolder);
            this.allFolders = Utils.sortAlphabetical(this.allFolders, (item) => item.name);
        }
    }

    private filterPermissions(tree, root, parentless?) {
        tree.children = (tree.children || [])
            .filter(t => {
                const perm = this.profileService.hasPermission(this.permission, this.facilityId, t.id);
                this.filterPermissions(t.children, root, !perm);
                if (perm && parentless) root.children.push(t);
                return perm;
            });
    }
}
