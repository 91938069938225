import { Channel } from '@weavix/models/src/channel/channel';
import { ChannelAlertsType,
        ChannelNotifications } from '@weavix/models/src/channel/channel-notifications';
import { PersonFacilityPredicate, PersonLike } from '@weavix/models/src/person/person-matcher';
import { evaluateCondition, matchPerson } from '../people/match-person';

export function isPersonIncludedInAdminDefinedChannel(person: PersonLike, channel: Channel, isPersonOnFacility: PersonFacilityPredicate): boolean {
    return matchPerson(channel, person, isPersonOnFacility);
}

function mergeDefaultPreferences(notification: ChannelNotifications, defaults: ChannelNotifications | undefined) {
    if (!defaults) return notification;

    // if a user is not allowed to Mute via console's ADC config, always allow PTT (channel not muted)
    // else if the user has no preferences for allowPtt, take default from console's ADC config 'soundMuted'
    if (defaults.allowMuting === false) notification.allowPtt = true;
    else if (notification?.allowPtt === undefined) notification.allowPtt = !defaults.soundMuted;

    // if a user is not allowed to disable notifications via console's ADC config, always allow Notifications (channel notification enabled)
    // else if the user has no preferences for allowNotification, take default from console's ADC config 'notificationsOff'
    if (defaults.allowDisablingNotifications === false) notification.allowNotifications = true;
    else if (notification?.allowNotifications === undefined) notification.allowNotifications = !defaults.notificationsOff;

    Object.values(ChannelAlertsType).forEach(x => {
        notification[x] = defaults[x];
    });

    return notification;
}

export function getUserAdcNotifictionPreferences(
    channel: Channel, 
    notification: ChannelNotifications | undefined | null, 
    person: PersonLike, 
    facilityLookup: PersonFacilityPredicate,
): ChannelNotifications {
    // copy notifications to avoid re-writing original notifications
    notification = { ...(notification ?? {}) };
    const applicableOverrides = channel.conditionalNotificationOverrides?.filter(conditional => {
        return !person ? false : conditional.conditions?.every(condition => evaluateCondition(condition, person, facilityLookup));
    });

    notification = mergeDefaultPreferences(notification, applicableOverrides?.[0] ?? channel?.defaultNotification);

    return notification;
}
