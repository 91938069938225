
<app-loading [loading]="isLoading" [theme]="'dark'"></app-loading>

<ng-container *ngIf="!isLoading && !error">
    <p class="no-data pT30" *ngIf="!activeDay || !sliderRange">
        {{'mapRange.error' | translate}}
    </p>

    <div
        id="playback-range-controls"
        [class]="'controls-container ' + type"
        *ngIf="activeDay && sliderRange"
    >
        <div
            class="close-dvr top-dvr"
            (click)="closeDvr()"
            *ngIf="type === DvrType.Day && collapsible"
        >
            <app-icon [faIcon]="'fas fa-times'"></app-icon>
        </div>

        <div id="playback-slider" class="map-range" [ngClass]="{'live': !inPlaybackMode}" *ngIf="type === DvrType.Day && !hideSlider">
            <mat-slider
                class="map-range-slider"
                thumbLabel
                [(ngModel)]="timestamp"
                [displayWith]="formatSliderLabel"
                [min]="sliderRange.start"
                [max]="sliderRange.end"
                (change)="handleDvrTimeSelection($event.value)"
                (input)="handleSliderMoved()"
            ></mat-slider>

            <div class="map-range-ticks" *ngIf="rangeTicks">
                <div class="tick" *ngFor="let tick of rangeTicks">
                    <p class="playback-tick-label label bold">{{tick.label}}</p>
                    <app-icon *ngIf="tick.icon" [faIcon]="tick.icon"></app-icon>
                </div>
            </div>
        </div>

        <div class="controls">
            <div class="control-button-group" *ngIf="!hideSlider">
                <div id="playback-previous-minute-control"
                    class="range-control mL10"
                    (click)="changeMinute(direction.Previous)"
                    [matTooltip]="'dvr.one-minute' | translate"
                    *ngIf="type === DvrType.Day"
                >
                    <app-icon [faIcon]="'fas fa-fast-backward'" [color]="colors.WHITE"></app-icon>
                </div>

                <!-- These second increment buttons will be used in a future story -->
                <!-- <div id="playback-previous-second-control"
                    class="range-control mL10"
                    (click)="changeSecond(direction.Previous)"
                    [matTooltip]="'dvr.one-second' | translate"
                    *ngIf="type === 'day'"
                >
                    <app-icon [faIcon]="'fas fa-backward'" [color]="colors.WHITE"></app-icon>
                </div> -->

                <div *ngIf="allowPlay">
                    <div
                        id="playback-play-control"
                        class="range-control mL10 mR10"
                        (click)="togglePlayback()"
                        [ngClass]="{'disabled': !inPlaybackMode}"
                    >
                        <app-icon
                            [faIcon]="isPlaying ? 'fas fa-pause' : 'fas fa-play'"
                            [color]="colors.WHITE"
                        ></app-icon>
                    </div>
                </div>

                <!-- <div
                    id="playback-next-second-control"
                    class="range-control mR10"
                    [ngClass]="{'disabled': !inPlaybackMode}"
                    (click)="changeSecond(direction.Next)"
                    [matTooltip]="'dvr.one-second' | translate"
                    *ngIf="type === 'day'"
                >
                    <app-icon [faIcon]="'fas fa-forward'"></app-icon>
                </div> -->

                <div id="playback-next-minute-control"
                     class="range-control mR10"
                     [ngClass]="{'disabled': !inPlaybackMode}"
                     (click)="changeMinute(direction.Next)"
                     [matTooltip]="'dvr.one-minute' | translate"
                     *ngIf="type === DvrType.Day"
                >
                    <app-icon [faIcon]="'fas fa-fast-forward'"></app-icon>
                </div>

                <div *ngIf="!hideLiveOption"
                    (click)="jumpToNow()"
                    id="playback-now-control"
                    class="range-control"
                >
                    <app-icon
                        class="live-circle"
                        [faIcon]="'fas fa-circle'"
                        [color]="inPlaybackMode ? colors.DISABLED_GRAY : colors.RED"
                    ></app-icon>

                    <p [ngStyle]="{'color': inPlaybackMode ? colors.DISABLED_GRAY  : colors.RED}"
                        class="live-text"
                    >
                        {{'dvr.live' | translate}}
                    </p>
                </div>
            </div>

            <div id="active-day" class="active-day-display">
                <p>{{ currentRangeLabel }}</p>
            </div>

            <div class="control-button-group" *ngIf="!removeDateSelection">
                <div class="date-control"
                     appClickOutside
                     (clickOutside)="showCalendarPicker = false;"
                >
                    <div id="playback-date-picker"
                         class="range-control"
                         (click)="toggleDatepicker()"
                    >
                        <app-icon [faIcon]="'far fa-calendar-alt'"></app-icon>
                    </div>
                    <mat-calendar
                        id="playback-calendar"
                        class="calendar"
                        [ngClass]="{'move-up': activeDay && sliderRange && !hideSlider && type !== DvrType.Custom}"
                        *ngIf="showCalendarPicker"
                        [minDate]="minDate"
                        [maxDate]="maxDate"
                        [startAt]="activeDay.date"
                        [selected]="selectedDate"
                        [headerComponent]="customHeader"
                        [dateClass]="dateClassBasedOnProps"
                        (selectedChange)="handleDateSelection($event)"
                    >
                    </mat-calendar>
                </div>

                <div class="close-dvr bottom-dvr"
                     (click)="closeDvr()"
                     *ngIf="type !== DvrType.Day && collapsible"
                >
                    <app-icon [faIcon]="'fas fa-times'"></app-icon>
                </div>
            </div>
        </div>
    </div>
</ng-container>
