<div class="wrapper">
    <div *ngIf="floatedLabel" class="floated-label-container">
        <span class="input-label">{{translate ? (floatedLabel | translate) : floatedLabel }}</span>
        <app-tooltip *ngIf="floatedLabelTooltip" [options]="floatedLabelTooltip" [doTranslate]="translate"></app-tooltip>
    </div>
    <div class="input-container" [ngClass]="{'disabled': disabled, 'bg-transparent pL0 pR0': noVisibleWrapper}">
        <div *ngIf="labelPosition === 'before'" class="label-container">
            <span class="label">{{translate ? (label | translate) : label }}</span>
            <app-tooltip style="padding-bottom: 0;" *ngIf="tooltip && !floatedLabel" [options]="tooltip" [doTranslate]="translate"></app-tooltip>
        </div>
        <div class="checkbox-container">
            <mat-checkbox class="compact" [id]="inputId" [ngClass]="{'light': lightTheme, 'disabled': disabled, 'circle': markerShape === 'circle'}"
                [ngModel]="checked" (ngModelChange)="setChecked($event)" (focus)="focus()" [labelPosition]="labelPosition" #input>
            </mat-checkbox>
        </div>
        <div *ngIf="labelPosition === 'after'" class="label-container">
            <span class="label">{{translate ? (label | translate) : label }}</span>
            <app-tooltip style="padding-bottom: 0;" *ngIf="tooltip && !floatedLabel" [options]="tooltip" [doTranslate]="translate"></app-tooltip>
        </div>
    
        <div *ngIf="showErrors">
            <mat-error *ngIf="formControl?.hasError('number')">{{ 'formValidation.number' | translate:{ label: (label | translate) } }}</mat-error>
        </div>
    </div>
</div>
