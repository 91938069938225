import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Modal, ModalActions, ModalActionType, ModalService } from 'weavix-shared/services/modal.service';
import { NetworkDeviceType, NetworkUtility } from 'components/network-indicator/network-utility';
import { debounce } from 'lodash';
import { Company } from 'weavix-shared/models/company.model';
import { Person } from 'weavix-shared/models/person.model';
import { MapWalt } from 'weavix-shared/models/weavix-map.model';
import { AlertService, ServiceError } from 'weavix-shared/services/alert.service';
import { CompanyService } from 'weavix-shared/services/company.service';
import { TranslationService } from 'weavix-shared/services/translation.service';
import { WaltService } from 'weavix-shared/services/walt.service';
import { FEATURE_ICONS } from 'weavix-shared/utils/feature.icons';
import { AutoUnsubscribe, getBatteryIcon } from 'weavix-shared/utils/utils';
import { FacilityService } from 'weavix-shared/services/facility.service';
import { ProfileService } from 'weavix-shared/services/profile.service';
import { PermissionAction } from '@weavix/models/src/permission/permissions.model';
import { Facility } from '@weavix/models/src/facility/facility';
import { DropdownItem } from 'components/dropdown/dropdown.model';

@AutoUnsubscribe()
@Component({
  selector: 'app-walt-detail',
  templateUrl: './walt-detail.component.html',
  styleUrls: ['./walt-detail.component.scss', '../map-detail-view.scss'],
})
export class WaltDetailComponent implements OnChanges {
    @Input() walt: MapWalt;
    @Output() closeOutput: EventEmitter<void> = new EventEmitter();
    loading: boolean = true;
    waltIcon = FEATURE_ICONS.radio.icon;
    locationIcon = FEATURE_ICONS.location.icon;
    networkIcon = NetworkUtility.getNetworkIcon(NetworkDeviceType.Wifi, 0);
    batteryIcon: string;
    company: Company;
    lastAssigned: string;
    version: string;
    person: Person;
    currentlyAssigned: boolean;
    canLogOut: boolean;
    hasDeviceManagementEditPerms: boolean;
    facilities: Facility[];
    facilityDropdown: DropdownItem[];
    private modalActions: ModalActions = {
        cancel: {
            show: true,
            textKey: 'generics.no',
        },
        submit: {
            show: true,
            textKey: 'generics.yes',
            buttonClass: 'blue-lt',
        },
    };
    debounceLogout = debounce(async () => await this.waltService.remoteLogout(this, this.walt.id), 500);
    debouncePlaySound = debounce(async () => await this.waltService.playWaltSound(this, this.walt.id), 500);
    changeFacility: Modal;
    waltFacility: string;

    constructor(
        private alertService: AlertService,
        private companyService: CompanyService,
        private waltService: WaltService,
        private modalService: ModalService,
        private translationService: TranslationService,
        private facilityService: FacilityService,
        private profileService: ProfileService,
    ) { }

    async ngOnChanges(changes: SimpleChanges) {
        if (changes.walt?.previousValue?.id !== changes.walt?.currentValue?.id) {
            this.loading = true;
            await this.setup();
        } else if (changes.walt?.previousValue?.person !== changes.walt?.currentValue?.person
            || changes.walt?.previousValue?.lastAssigned?.personId !== changes.walt?.currentValue?.lastAssigned?.personId
            || changes.walt?.previousValue?.wrangler !== changes.walt?.currentValue?.wrangler
            || changes.walt?.previousValue?.facilityId !== changes.walt?.currentValue?.facilityId) {
            await this.setup();
        }
    }

    private async setup(): Promise<void> {
        if (!this.batteryIcon) this.loading = true;
        try {
            this.hasDeviceManagementEditPerms = this.profileService.hasFacilitiesPermission(PermissionAction.EditDeviceManagement, this.walt.facilityId);
            this.waltFacility = this.walt.facilityId;
            await this.populateDropdown();
            this.setBattery();
            this.setVersion();
            await this.setAssignedTo();
            await this.setCompany();
            await this.setPerson();
            await this.setCanLogOut();
        } catch (e) {
            this.alertService.sendServiceError(e, ServiceError.Get, 'walt.walt');
        } finally {
            this.loading = false;
        }
    }

    private async populateDropdown() {
        this.facilities = (await this.facilityService.getMyFacilitiesOnCurrentAccount(this))
            .filter(x => x.id === this.walt.facilityId || this.profileService.hasFacilitiesPermission(PermissionAction.EditDeviceManagement, x.id));
        this.facilityDropdown = this.facilities.map(f => {
            return {
                label: f.name,
                key: f.id,
            };
        });
    }

    private setVersion() {
        this.version = this.walt?.version;
    }

    private async setAssignedTo() {
        this.lastAssigned = this.waltService.getWaltLastAssignedName(this.walt);
    }

    private async setCanLogOut() {
        if (!this.hasDeviceManagementEditPerms) this.canLogOut = false;
        else if (WaltService.isCurrentlyAssigned(this.walt) && WaltService.waltActive(this.walt) && !this.walt.poweredOff) this.canLogOut = true;
        else this.canLogOut = false;
    }

    onFacilityOutput(selected: DropdownItem) {
        this.waltFacility = selected.key;
        const facility = this.facilities.find(f => f.id === selected.key);
        this.changeFacility = {
            isOpen: true,
            width: 450,
            actionsAlignment: 'right',
            header: {
                textKey: 'walt.site-change.header',
                textAlignment: 'left',
                showSeparator: true,
            },
            actions: {
                [ModalActionType.submit]: {
                    textKey: 'generics.confirm',
                    show: true,
                },
                [ModalActionType.cancel]: {
                    textKey: 'generics.cancel',
                    show: true,
                },
            },
            fullScreen: false,
            content: false,
            textAlignment: 'left',
            padding: 20,
            textKey: 'walt.site-change.body',
            textStyle: {
                'padding-top': '20px',
                'padding-bottom': '10px',
                'font-size': '16px',
            },
            params: { siteName: facility.name },
        };
    }

    async updateWaltFacility(action: ModalActionType) {
        this.changeFacility = null;
        if (action !== ModalActionType.submit) {
            this.waltFacility = this.walt.facilityId;
            return;
        }
        try {
            this.alertService.setAppLoading(true);
            await this.waltService.setWaltFacility(this, this.walt.id, this.waltFacility);
            this.alertService.sendSuccess('walt.site-change.success');
        } catch (e) {
            this.waltFacility = this.walt.facilityId;
            this.alertService.sendServiceError(e, ServiceError.Update, 'walt.walt');
        } finally {
            this.alertService.setAppLoading(false);
        }
    }

    private async setPerson() {
        try {
            this.currentlyAssigned = WaltService.isCurrentlyAssigned(this.walt);
            const lastAssignedName = this.waltService.getWaltLastAssignedName(this.walt);
            // handles edge case where person is fully removed from the database...
            if (this.currentlyAssigned &&
                lastAssignedName !== this.translationService.getImmediate('generics.unknown')
            ) {
                this.person = await this.waltService.getWaltLastAssignedPerson(this, this.walt);
            } else {
                this.person = null;
            }
        } catch (e) {
            console.error('walt-detail.component.ts: Person could not be found...', e);
        }
    }

    private async setCompany() {
        this.company = null;
        const { companyId } = this.walt.lastAssigned;
        if (!companyId) return;
        this.company = await this.companyService.get(this, companyId);
    }

    private setBattery() {
        this.batteryIcon = getBatteryIcon(this.walt.battery * 100);
    }

    async playSound() {
        this.debouncePlaySound();
    }

    async remoteLogout() {
        const modalResult = await this.modalService.confirm('', this.translationService.getImmediate('walt.confirm-remote-logout'), this.modalActions, true);
        if (modalResult.action === ModalActionType.submit) this.debounceLogout();
    }

    handleClose() {
        this.closeOutput.emit();
    }
}
