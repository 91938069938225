import { ReplaySubject } from 'rxjs';
import { FeatureIconConfig, FEATURE_ICONS } from '../utils/feature.icons';
import { Company } from './company.model';
import { CheckedInMapPerson } from '@weavix/models/src/item/confined-space';
import { Craft } from './craft.model';
import { EventUpdateResponse, UpdateEvent } from '@weavix/models/src/badges/event';
import { Beacon, Facility, TrackingCircle, WifiRouter, Wrangler } from './facility.model';
import { FloorPlan } from './floor-plan.model';
import { GeofenceTypeCategory } from './geofence-type.model';
import { AppIcon } from './icon.model';
import { AnyItem, Item, ItemType } from './item.model';
import { Point, Vertices } from './location.model';
import { Person } from './person.model';
import { ResultType } from './search.model';
import { Structure } from './structure.model';
import { Walt } from './walt.model';
import { SiteSurveyMeasurement, SiteSurveyNetwork } from '@weavix/models/src/facility/site-survey';
import { FilterResultType } from '@weavix/models/src/map/map';

export * from '@weavix/models/src/map/map';

export const MAX_ZOOM_LEVEL = 25;

export enum MapScope {
    Account = 'account',
    Facility = 'facilities',
}

export interface WeavixMap {
    name: string;
    lat: number;
    lng: number;
    zoom: number;
    bounds?: google.maps.LatLngBounds;
    id?: string;
    tags?: string[];
    crafts?: string[];
}

export interface Geofence {
    id?: string;
    name: string;
    color: string;
    vertices: Vertices;
    tags?: string[];
    areaRestricted?: boolean;
    facilityId?: string;
    classifications?: GeofenceClassification[];
    geofenceTypeId?: string;
    floorPlanId?: string;
}

export type MapBeacon = Beacon & MapMarker;
export type MapWifiRouter = WifiRouter & MapMarker;
export type MapWrangler = Wrangler & MapMarker;
export type MapSurvey = SiteSurveyNetwork & SiteSurveyMeasurement & MapMarker & { pinnedLocation: [number, number] };

export interface MapTrackingCircle extends TrackingCircle {
    marker?: RichMarker & { listener?: google.maps.MapsEventListener };
    circle?: google.maps.Circle;
    listener?: google.maps.MapsEventListener;
    zIndex?: number;
}

export interface MapAccuracyCircle {
    id: string;
    circle?: google.maps.Circle;
    listener?: google.maps.MapsEventListener;
    latitude: number;
    longitude: number;
    radius: number;
}

export interface GeofenceClassification {
    craft: Craft;
    categories: GeofenceTypeCategory[];
}

export interface MapGeofence extends Geofence {
    polygon?: google.maps.Polygon;
    listener?: google.maps.MapsEventListener;
}

export interface MapMarker {
    marker?: RichMarker;
    listener?: google.maps.MapsEventListener;
    closeListener?: google.maps.MapsEventListener;
}

export type MapPerson = Person & MapMarker & PersonSubjects & { action?: string };

export interface PersonSubjects {
    checkedIn$?: ReplaySubject<CheckedInMapPerson>;
}

export type MapItem = AnyItem & MapMarker;

export type MapWalt = Walt & MapMarker & { person?: Person };
export interface MapCompany extends Company {
    filterChecked?: boolean;
}

export interface LatLng {
    lat: number;
    lng: number;
}

export const CENTER_POS: number = 50; // half of the width of map label in map.scss

export interface DvrPeople {
    [id: string]: DvrPerson;
}
export interface DvrPerson extends Person {
    latestEvent: any;
}
export interface DvrItems {
    [id: string]: DvrItem;
}
export interface DvrItem extends Item {
    latestEvent: any;
}

export interface GeofenceWithEvents extends Geofence {
    events: UpdateEvent[];
}

export interface DvrState {
    facility: Facility;
    accountId: string;
    events: {
        [key: string]: EventUpdateResponse[];
    };
    range: {
        from: Date;
        to: Date;
    };
    people?: {[id: string]: DvrPerson};
    items?: {[id: string]: DvrItem};
}

export interface ClusterItem {
    id: string;
    type: FilterResultType;
}

export interface ClusterMapMarker extends google.maps.Marker {
    type: ResultType;
    id: string;
}

export const enum MapMarkerItemMode {
    Item = 'item',
}
export interface MapFilterState {
    filters: { [key in MapFilterCategory]?: boolean }; // Includes all filters except companies
    tags: string[];
    crafts: string[];
    companiesList: string[];
    itemTypes: string[];
}

export interface MapViewState {
    location: Point;
    bounds: Vertices;
    zoom: number;
}

export interface CustomMarkerOptions extends google.maps.ReadonlyMarkerOptions {
    id: string;
    type: ResultType;
    locationAccuracy?: number;
    locationDate?: Date;
}

export interface RichMarker extends google.maps.Marker {
    id: string;
    locationAccuracy?: number;
    locationDate?: Date;
    circle?: google.maps.Circle;
    type: FilterResultType;
    wispId?: string;
    selected?: boolean;
    getContent(): string;
    setContent(content: string): void;
}

export interface RichMarkerOptions extends CustomMarkerOptions {
    content: string;
    shadow?: boolean;
    hideCloseIcon?: boolean;
    customCalloutContent?: string;
    customCalloutActions?: CustomCalloutAction[];
    removeIfClustered: boolean; // if items with this property become part of a cluster, they will be removed
    data?: any;
}

export interface CustomCalloutAction {
    name: string;
    label?: string;
    icon?: string;
    hoverIcon?: string;
    class?: string;
    show?: (obj: any) => boolean;
}

export interface RichMarker extends google.maps.Marker {
    id: string;
    locationAccuracy?: number;
    locationDate?: Date;
    circle?: google.maps.Circle;
    type: FilterResultType;
    wispId?: string;
    selected?: boolean;
    getContent(): string;
    setContent(content: string): void;
}

export interface MarkerClusterer {
    addMarker(marker: google.maps.Marker, noDraw: boolean): void;
    removeMarker(marker: google.maps.Marker, noDraw: boolean): boolean;
    repaint(): void;
    getMarkers(): google.maps.Marker[];
    setMap(map: google.maps.Map): void;
    setExceptionMarkerIds(exceptionMarkerIds: {[key: string]: FilterResultType }): void;
}

export interface ClusterIconInfo {
    text: string;
    index: number;
    title: string;
}

export interface ClusterIconStyle {
    url: string;
    height: number;
    width: number;
    anchorText?: number[];
    anchorIcon?: number[];
    textColor?: string;
    textSize?: number;
    textDecoration?: string;
    fontWeight?: string;
    fontStyle?: string;
    fontFamily?: string;
    backgroundPosition?: string;
}

export interface MarkerClustererOptions {
    gridSize?: number;
    maxZoom?: number;
    zoomOnClick?: boolean;
    averageCenter?: boolean;
    minimumClusterSize?: number;
    ignoreHidden?: boolean;
    title?: string;
    calculator?: (markers: google.maps.Marker[], numStyles: number) => ClusterIconInfo;
    clusterClass?: string;
    styles?: ClusterIconStyle[];
    enableRetinaIcons?: boolean;
    batchSize?: number;
    batchSizeIE?: number;
    imagePath?: string;
    imageExtension?: string;
    imageSizes?: number[];
}

export interface MapView {
    lat: number;
    lng: number;
    bounds: google.maps.LatLngBounds;
    zoom: number;
}

export enum MapLayerToggles {
    FacilityFloorPlan = 'facility-floor-plan',
    Geofences = 'geofences',
    HeatMap = 'heat-map',
    Individuals = 'individuals',
    Items = 'items',
    Wilmas = 'wilmas',
}

export enum MapFilterCategory {
    Buildings = 'buildings',
    Companies = 'companies',
    Crafts = 'crafts',
    Geofences = 'geofences',
    Groups = 'groups',
    FloorPlans = 'floorPlans',
    ItemTypes = 'itemTypes',
    Items = 'items',
    Levels = 'levels',
    LocationReporting = 'locationReporting',
    People = 'people',
    Structures = 'structures',
    Walt = 'walt',
    WaltStatus = 'waltStatus',
    Site = 'site',
    Wilma = 'wilma',
}

export const filterCategroyToTranslationKey: {[key in MapFilterCategory]?: string} = {
    [MapFilterCategory.Crafts]: 'crafts.crafts',
    [MapFilterCategory.Companies]: 'shared.company.companies',
    [MapFilterCategory.Geofences]: 'shared.geofence.geofences',
    [MapFilterCategory.Structures]: 'structures.structures',
    [MapFilterCategory.WaltStatus]: 'structures.structures',
};

export const CategoryToResultType: {[key in MapFilterCategory]?: FilterResultType} = {
    [MapFilterCategory.Companies]: FilterResultType.Company,
    [MapFilterCategory.Crafts]: FilterResultType.Craft,
    [MapFilterCategory.Geofences]: FilterResultType.Geofence,
    [MapFilterCategory.People]: FilterResultType.Person,
};

export const CategoryToIcon: {[key in MapFilterCategory]?: FeatureIconConfig} = {
    [MapFilterCategory.Structures]: FEATURE_ICONS.structures,
    [MapFilterCategory.Companies]: FEATURE_ICONS.companies,
    [MapFilterCategory.Crafts]: FEATURE_ICONS.craft,
    [MapFilterCategory.FloorPlans]: FEATURE_ICONS.floorPlans,
    [MapFilterCategory.Geofences]: FEATURE_ICONS.geofences,
    [MapFilterCategory.Levels]: FEATURE_ICONS.levels,
    [MapFilterCategory.LocationReporting]: FEATURE_ICONS.location,
    [MapFilterCategory.WaltStatus]: FEATURE_ICONS.waltStatus,
    [MapFilterCategory.Site]: FEATURE_ICONS.sites,
};

export type MapFilterRowDisplay = 'person' | 'item' | 'default';
export interface MapFilterResult {
    name: string;
    key: string;
    category: MapFilterCategory;
    type: FilterResultType;
    selected?: boolean;
    hidden?: boolean;
    setSelected?: (this: MapFilterResult, selected: boolean, original?: boolean, click?: boolean) => any;
    disabled?: (this: MapFilterResult) => boolean;
    appendixText?: (this: MapFilterResult) => string;
    icon?: AppIcon;
    appendixIcon?: AppIcon;
    childCount?: number;
    displayType?: MapFilterRowDisplay;
    data?: Person | Geofence | Company | Item & { itemType: ItemType, owner: Company } | Structure | FloorPlan;
    childrenSelected?: boolean;
    children?: {[key: string]: MapFilterResult};
    entities?: {[key: string]: MapFilterResult}; // entities you want to keep track of within category (i.e. people in company)
    filters?: {[key in MapFilterCategory]?: {[id: string]: MapFilterResult}};
    customClickAction?: () => void;
    multiselect?: boolean;
}

export enum MapTableMode {
    Map = 'map',
    List = 'list',
}

export enum MapMode {
    Active = 'active',
    Satellite = 'satellite',
    NoSatellite = 'noSatellite',
    All = 'all',
}

export const NO_SATELLITE_TYPE_ID: string = 'no-satellite';

export enum MapType {
    People = 'people',
    Communications = 'communications',
    MeetingPeople = 'meeting-people',
}

export interface FilterTree {
    category: MapFilterCategory;
    subcategories?: FilterTree[];
}
export interface FilterEntity {
    filters: { [key in MapFilterCategory]?: { [key: string]: number; }; };
    weight?: number;
    id?: string;
    category?: MapFilterCategory;
    details?: Partial<MapFilterResult>;
}

export interface MapCacheObject {
    geofences: {[id: string]: {[key: string]: number}};
    points: {[id: string]: number};
    heatmap: {[key: string]: { location: google.maps.LatLng; weight: number; }};
    filters: { [id: string]: number };
}

/**
 * Generates a unique key for a given MapWrangler.
 * This key is used to group wranglers that are in the same location and have the same structureId and level.
 *
 * The key is composed of the wrangler's location (x and y coordinates), structureId, and level.
 * If structureId or level are not provided, they default to 0.
 *
 * @param {MapWrangler} wrangler - The wrangler for which to generate a key.
 * @returns {string} The generated key.
 */
export const generateWranglerGroupKey = (wrangler: MapWrangler): string => {
    return `${wrangler.location[0]}:${wrangler.location[1]}:${wrangler.structureId ?? 0}:${wrangler.level ?? 0}`;
};